<template>
    <div class="formula-container">
      <div class="formula-item" @mouseenter="handleMouseEnter" @mouseleave="handleMouseLeave">
        <div :class="['formula-value', { 'hover': hover }]">降耗效益</div> 
      </div>
      <canvas ref="plusCanvas" class="formula-symbol"></canvas>
      <div class="formula-item" @mouseenter="handleMouseEnter" @mouseleave="handleMouseLeave">
        <div :class="['formula-value', { 'hover': hover }]">配额效益</div> 
      </div>
      <canvas ref="plusCanvas2" class="formula-symbol"></canvas>
      <div class="formula-item" @mouseenter="handleMouseEnter" @mouseleave="handleMouseLeave">
        <div :class="['formula-value', { 'hover': hover }]">CCER开发效益</div> 
      </div>
      <canvas ref="equalCanvas" class="formula-symbol"></canvas>
      <div class="formula-item" @mouseenter="handleMouseEnter" @mouseleave="handleMouseLeave">
        <div :class="['formula-value', { 'hover': hover }]">增进效益</div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        hover: false
      };
    },
    mounted() {
      this.drawSymbols();
    },
    methods: {
      handleMouseEnter() {
        this.hover = true;
      },
      handleMouseLeave() {
        this.hover = false;
      },
      drawSymbols() {
        const plusCanvas = this.$refs.plusCanvas;
        const plusCtx = plusCanvas.getContext('2d');
        const plusCanvas2 = this.$refs.plusCanvas2;
        const plusCtx2 = plusCanvas2.getContext('2d');
        const equalCanvas = this.$refs.equalCanvas;
        const equalCtx = equalCanvas.getContext('2d');
  
        const setCanvasDimensions = (canvas, ctx, symbol) => {
          canvas.width = 50;
          canvas.height = 50;
          ctx.font = 'bold 32px Arial';
          ctx.fillStyle = '#3498db';
          ctx.textAlign = 'center';
          ctx.textBaseline = 'middle';
          ctx.fillText(symbol, canvas.width / 2, canvas.height / 2);
        };
  
        setCanvasDimensions(plusCanvas, plusCtx, '+');
        setCanvasDimensions(plusCanvas2, plusCtx2, '+');
        setCanvasDimensions(equalCanvas, equalCtx, '=');
      }
    }
  }
  </script>
  
  <style scoped>
  @import 'animate.css';
  
  .formula-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    font-size: 32px;
    color: #2c3e50;
  }
  
  .formula-item {
    margin: 10px;
    position: relative;
    display: inline-block;
  }
  
  .formula-value {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 150px;
    height: 150px;
    background: linear-gradient(135deg, #f3f3f3 25%, #e8e8e8 75%);
    border-radius: 50%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    font-size: 1em;
    text-align: center;
    padding: 20px;
    box-sizing: border-box;
    color: #3498db;
    position: relative;
  }
  
  .formula-value::before {
    content: "";
    position: absolute;
    top: -5px;
    left: -5px;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    z-index: -1;
  }
  
  .formula-value::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 50%;
    z-index: 1;
    transition: opacity 0.3s ease;
  }
  
  .formula-value.hover::after {
    opacity: 0.5;
  }
  
  .formula-value:hover {
    transform: translateY(-10px) scale(1.05);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
  
  .formula-symbol {
    margin: 0 10px;
  }
  
  /* Mobile Styles */
  @media (max-width: 768px) {
    .formula-container {
      flex-direction: row; /* Keep horizontal layout */
      overflow-x: auto; /* Allow horizontal scrolling */
    }
  
    .formula-item {
      flex: 1 1 auto;
      margin: 0px;
    }
  
    .formula-value {
      width: 100px;
      height: 100px;
      font-size: 0.8em;
    }
  
    .formula-symbol {
      margin: 0 5px;
    }
  }
  </style>
  