<template>
    <div class="container">
      <div class="title">我们的优势</div>
      <div class="advantages">
        <div class="advantage-item" v-for="item in advantages" :key="item.number">
          <h3>{{ item.number }}</h3>
          <h4>{{ item.title }}</h4>
          <p>{{ item.description }}</p>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "Advantages",
    data() {
      return {
        advantages: [
          {
            number: "1",
            title: "精英团队",
            description: "公司与国内多家试点交易拥有密切合作关系，是深圳、重庆、四川、湖北、天津交易所的机构会员单位。"
          },
          {
            number: "2",
            title: "系统化培训",
            description: "公司是海峡股权交易中心、天津碳排放权交易所碳中和业务产品提供商。"
          },
          {
            number: "3",
            title: "强大网络",
            description: "公司与北环所达成战略合作协议。"
          },
          {
            number: "4",
            title: "强大网络",
            description: "公司与中国质量认证中心、中国船级社、中国检验公司吉林分公司战略合作伙伴关系。"
          }
        ]
      };
    }
  };
  </script>
  
  <style scoped>
  :root {
    --primary-color: #f3a93c;
    --text-color: #666;
    --background-color: #f5f5f5;
    --card-background: white;
    --card-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: var(--background-color);
  }
  
  .container {
    text-align: center;
    padding: 2rem;
  }
  
  .title {
    font-size: 2.5rem;
    margin-bottom: 2rem;
  }
  
  .advantages {
    display: flex;
    justify-content: center;
    gap: 2rem;
    flex-wrap: wrap;
  }
  
  .advantage-item {
    width: 20rem;
    background-color: var(--card-background);
    padding: 1.5rem;
    border-radius: 0.625rem;
    box-shadow: var(--card-shadow);
    margin: 0.625rem;
  }
  
  .advantage-item h3 {
    font-size: 2rem;
    color: var(--primary-color);
    margin-bottom: 0.625rem;
  }
  
  .advantage-item h4 {
    font-size: 1.2rem;
    margin-bottom: 1rem;
  }
  
  .advantage-item p {
    font-size: 1rem;
    line-height: 1.5;
    color: var(--text-color);
  }
  
  @media (max-width: 48rem) {
    .advantages {
      align-items: center;
    }
  
    .advantage-item {
      width: 90%;
      padding: 1rem;
    }
  
    .title {
      font-size: 2rem;
    }
  
    .advantage-item h3 {
      font-size: 1.8rem;
    }
  
    .advantage-item h4 {
      font-size: 1.1rem;
    }
  
    .advantage-item p {
      font-size: 0.9rem;
    }
  }
  </style>
  