<template>
    <div class="box">
      <div class="development_left">
        <el-text class="text-list" size="large">
          <div class="card" v-for="(item, index) in textListleft" :key="index">
            <div>
              <img :src="listyle" class="listyle-icon"><span>{{ item.title }}</span>
            </div>
          </div>
        </el-text>
        <div class="EffectFormula">
          <EffectFormula />
        </div>
      </div>
      <div class="about-dotted-line"></div>
      <div class="development_right">
        <el-text class="text-list" size="large">
          <div class="card" v-for="(item, index) in textList" :key="index">
            <div>
              <img :src="listyle" class="listyle-icon"><span>{{ item.title }}</span>
            </div>
            <span>{{ item.text }}</span>
          </div>
        </el-text>
      </div>
    </div>
  </template>
  
  <script lang="ts">
  import { ref } from "vue";
  import listyle from "@/assets/copy4.png";
  import EffectFormula from '@/components/Home/EffectFormula.vue';
  
  export default {
    name: 'development',
    components: {
      EffectFormula
    },
    setup() {
      const textList = ref([
        {
          title: "开发咨询服务：",
          text: "主要针对减排项目业主提供申报文件编写，协助减排项目备案以及减排备案，减排量备案，减排量撮合转让，减排量委托销量等服务，公司收取相应服务费用，从而获得收益。",
        },
        {
          title: "合作开发服务",
          text: "公司和减排项目业主共同将符合温室气体自愿减排规划的项目按照主管部门要求，申报成为减排项目，协助完成减排项目备案以及减排备案。",
        }
      ]);
      const textListleft = ref([
        {
          title: "通过节能设备，技术，直接降低石化，外购电力的消耗；",
        },
        {
          title: "降耗同时，降低碳排放，相对增加企业配额的富余量；",
        },
        {
          title: "针对部分项目，结合实际进行碳资产开发；",
        }
      ]);
      return {
        listyle,
        textList,
        textListleft
      };
    }
  };
  </script>
  
  <style lang="scss" scoped>
  .box {
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
  }
  
  .about-dotted-line {
    width: 1px;
    height: 100%;
    border-left: 3px dashed #abafb1;
    z-index: 1;
  }
  
  .development_left, .development_right {
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: space-between;
  
    .text-list {
      height: 50%;
      display: flex;
      flex-wrap: wrap;
      align-content: space-around;
    }
  
    .card {
      margin: 10px;
      width: 100%;
    }
  
    .listyle-icon {
      margin-right: 5px;
    }
  }
  
  .development_left {
    width: 60%;
  
    .EffectFormula {
      margin-top: 50px;
      height: 50%;
      width: 100%;
    }
  }
  
  .development_right {
    width: 20%;
  }
  
  @media (max-width: 768px) {
    .box {
      flex-direction: column;
      align-items: center;
    }
  
    .development_left, .development_right {
      width: 100%;
      height: auto;
  
      .text-list {
        height: auto;
      }
  
      .EffectFormula {
        margin-top: 20px;
        width: 100%;
      }
    }
  
    .about-dotted-line {
      width: 100%;
      height: 1px;
      border-left: none;
      border-top: 3px dashed #abafb1;
      margin: 20px 0;
    }
  
    .card {
      margin: 5px;
    }
  
    .listyle-icon {
      margin-right: 3px;
    }
  }
  </style>
  