<template>
  <footer class="footer-menu">
    <div :class="isMobile ? 'footer-content-model' : 'footer-content'">
      <div class="lis" v-for="(item, index) in menuItems" :key="index">
        <div style="display: flex;flex-wrap: wrap;place-content: center;align-items: center; margin-bottom: 10px;">
          <div><img :src="listyle" style="margin-right: 5px; height: 100%;width: auto;"></div>
          <h2 style="line-height: 27px;">{{ item.title }}</h2>
        </div>
        <ul>
          <li v-for="(subItem, subIndex) in item.subItems" :key="subIndex">{{ subItem }}</li>
        </ul>
      </div>
      <div class="lis follow-us">
        <h2 style="margin-bottom: 10px;">关注我们</h2>
        <img :src="qrcode" alt="QR Code" />
      </div>
    </div>
  </footer>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, onBeforeUnmount } from 'vue';
import listyle from '@/assets/copy4.png'; // 确保路径正确
import qrcode from '@/assets/qrcode.jpg'; // 确保路径正确

export default defineComponent({
  name: 'FooterMenu',
  data() {
    return {
      listyle,
      qrcode,
      menuItems: [
        {
          title: '提交BP',
          subItems: ['huanqiulvse@yeah.net']
        },
        {
          title: '工作机会',
          subItems: ['huanqiulvse@yeah.net']
        }
      ]
    };
  },
  setup() {
    const isMobile = ref(window.innerWidth <= 768);

    const updateIsMobile = () => {
      isMobile.value = window.innerWidth <= 768;
    };

    onMounted(() => {
      window.addEventListener('resize', updateIsMobile);
    });

    onBeforeUnmount(() => {
      window.removeEventListener('resize', updateIsMobile);
    });

    return {
      isMobile
    };
  }
});
</script>

<style lang="scss" scoped>
.footer-menu {
  background-color: #779649;
  color: #fff;
  padding: 20px 0;
  text-align: center;
}

.footer-content {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
}

.footer-content-model {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: column;
}

.lis {
  margin: 20px;
}

.lis ul {
  list-style: none;
  padding: 0;
}

.lis ul li {
  margin: 5px 0;
}

.follow-us img {
  width: 100px;
  height: 100px;
}
</style>
