<template>
    <div class="mission-box">
        <div class="mission-img">
            <el-image class="image" :src="url" fit="contain" />
        </div>
        <div class="mission-li">
            <el-text class="text-list" size="large">
                <div class="card" v-for="(item, index) in textList" :key="index">
                    <div>
                        <img :src="listyle" style="margin-right: 5px;"><span>{{ item.title }}</span>
                    </div>
                    <span>{{ item.text }}</span>
                </div>
            </el-text>
        </div>
    </div>
</template>

<script>
import { ref } from "vue";
import url from "@/assets/home_advantage_1.jpg";
import listyle from "@/assets/copy4.png";

export default {
    setup() {
        const textList = ref([
            {
                title: "企业使命：",
                text: "专注应对气候变化和温室气体减排领域，为全球绿色，美丽中国生态文明建设贡献力量，积极参与应对气候变化全球治理。",
            },
            {
                title: "企业愿景：",
                text: "根据全球碳达峰碳中和各阶段目标，成为专业的双碳产业战略研究机构和服务专家。",
            },
            {
                title: "企业使命：",
                text: "为政府和产业提供应对气候变化、碳达峰碳中和解决方案，为企业绿色低碳发展提供综合性技术支撑。",
            },
        ]);
        return {
            url,
            textList,
            listyle
        };
    },
};
</script>

<style lang="scss" scoped>
.mission-box {
    height: 520px;
    display: flex;

    .mission-img {
        width: 60%;
        height: 100%;
        overflow: hidden;

        .image {
            width: 100%;
            height: 100%;
            opacity: 1;
            transition: transform 0.3s ease, opacity 0.3s ease;
            user-select: none;

            &:hover {
                opacity: 1;
                transform: scale(1.2);
            }
        }
    }

    .mission-li {
        width: 40%;
        //   background: #ccc;
        display: flex;
        align-items: center;
        justify-content: center;

        .text-list {
            width: 80%;
            height: 100%;
            display: flex;
            flex-wrap: wrap;
            align-content: space-around;
        }

        .card {
            margin: 10px;
            width: 100%;

        }
    }
}
</style>