<template>
    <div class="carousel-container">
        <div class="arrow left" @click="slideLeft">&#9664;</div>
        <div class="carousel-wrapper" ref="carousel">
            <div class="carousel-slide" v-for="(image, index) in images" :key="index">
                <div>
                    <el-image style="width: 100%; height: auto" :src="image.url" :zoom-rate="1.2" :max-scale="7"
                        :min-scale="0.2" fit="cover" />
                </div>
                <div class="body_text">
                    <h1>{{ image.title }}</h1>
                    <el-text class="mx-1" size="larger" style="font-family: fantasy;font-weight: 500;">
                        {{ image.text }}
                    </el-text>
                </div>

            </div>
        </div>
        <div class="arrow right" @click="slideRight">&#9654;</div>
    </div>
</template>

<script>
import image1 from '@/assets/image1.jpg';
import image2 from '@/assets/image2.jpg';
import image3 from '@/assets/image3.jpg';

export default {
    name: 'Carousel',
    data() {
        return {
            images: [{
                url: image1,
                title: '企业使命：',
                text: '专注应对气候变化和温室气体减排领域，为全球绿色，美丽中国生态文明建设贡献力量，积极参与应对气候变化全球治理'
            }, {
                url: image2,
                title: '企业使命：',
                text: '专注应对气候变化和温室气体减排领域，为全球绿色，美丽中国生态文明建设贡献力量，积极参与应对气候变化全球治理'
            }, {
                url: image3,
                title: '企业使命：',
                text: '专注应对气候变化和温室气体减排领域，为全球绿色，美丽中国生态文明建设贡献力量，积极参与应对气候变化全球治理'
            }],
            slideWidth: 0,
            offset: 0,
        };
    },
    mounted() {
        this.$nextTick(() => {
            const carousel = this.$refs.carousel;
            if (carousel) {
                const slides = carousel.getElementsByClassName('carousel-slide');
                if (slides.length > 0) {
                    this.slideWidth = slides[0].offsetWidth;
                }
            }
        });
    },
    methods: {
        slideLeft() {
            this.offset += this.slideWidth;
            if (this.offset > 0) {
                // 如果超出最左边，跳转到最后一张图的位置
                this.offset = -(this.images.length - 1) * this.slideWidth;
            }
            this.$refs.carousel.style.transform = `translateX(${this.offset}px)`;
        },
        slideRight() {
            this.offset -= this.slideWidth;
            const maxOffset = -(this.images.length - 1) * this.slideWidth;
            if (this.offset < maxOffset) {
                // 如果超出最右边，跳转到第一张图的位置
                this.offset = 0;
            }
            this.$refs.carousel.style.transform = `translateX(${this.offset}px)`;
        },
    },
};
</script>

<style scoped>
.carousel-container {
    position: relative;
    width: 100%;
    overflow: hidden;
    display: flex;
    align-items: center;
    margin: 20px;
}

.carousel-wrapper {
    display: flex;
    transition: transform 0.5s ease;
}

.carousel-slide {
    display: flex;
    flex: 0 0 auto;
    width: 100%;
    box-sizing: border-box;
    text-align: center;
    background-color: #f0f0f0;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
}

.body_text {
    width: 100%;
    /* height: 200px; */
    display: flex;
    padding: 20px 0px;
    background: #00000000;
}

.arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: 24px;
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    padding: 10px;
}

.left {
    left: 10px;
    z-index: 1;
}

.right {
    right: 10px;
    z-index: 1;
}
</style>