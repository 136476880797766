<template>
    <div class="structure-box">
        <div class="bg"></div>
        <div class="structure-text">
            <div class="image-container" @mouseover="hover = true" @mouseleave="hover = false">
                <img :src="people" />
                <div class="overlay" :class="{ active: hover }">
                    <p>林建东</p>
                </div>
            </div>
            <div class="text-box">
                <div class="textiles">
                    <div class="textiles-img">
                        <img :src="listyle" class="icon" style="margin-right: 10px;"/>
                        <div class="textile">Lin Jian Dong</div>
                    </div>
                    <div class="items">
                        <div v-for="(item, index) in overlays" :key="index" class="item">
                            <p style="margin-right: 20px;">{{ item.text }}</p>
                            <p v-if="item.Position" class="position">{{ item.Position }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="divider"></div>
    </div>
</template>
<script>
import { ref, onMounted } from "vue";
import people from "@/assets/people1.jpg";
import listyle from "@/assets/copy4.png";

export default {
    setup() {
        const isPhone = ref(window.innerWidth <= 800);
        const hover = ref(false);
        const overlays = ref([
            { text: '沙特阿拉伯金属及矿业证券交易所', Position: '股东' },
            { text: '门萨会员俱乐部会员', Position: null },
            { text: '澳大利亚国家证券交易所', Position: '股东' },
            { text: '香港碳排放交易所', Position: '总监（2021年）' },
            { text: '香港记忆学总会创始人', Position: null },
        ]);

        onMounted(() => {
            window.addEventListener('resize', () => {
                isPhone.value = window.innerWidth <= 800;
            });
        });

        return { people, hover, listyle, overlays, isPhone };
    },
};
</script>
<style lang="scss" scoped>
.structure-box {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .bg {
        width: 100%;
        height: 150px !important;
        background: #000;
    }

    .structure-text {
        width: 80% !important;
        flex-direction: column !important;
        transform: translateY(-100px) !important;
        padding: 10px !important;
        gap: 10px !important;
        display: flex;
        align-items: center;

        .text-box {
            width: 100% !important;
            margin-bottom: 20px !important;
        }

        .image-container {
            width: 80% !important;
            height: auto !important;
            position: relative;
            overflow: hidden;
            border-radius: 20px;
            img {
                position: static !important;
                height: auto !important;
                width: 100% !important;
                transition: transform 0.3s ease, opacity 0.3s ease;
                user-select: none;
                overflow: hidden;
                border-radius: 20px;
            }

            .overlay {
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 0;
                background: rgba(0, 0, 0, 0.4);
                transition: height 1s ease;
                display: flex;
                align-items: center;
                padding-left: 20px;

                p {
                    color: #fff;
                    border-left: 10px solid #779649;
                    font-size: 52px;
                    padding-left: 20px;
                    margin: 0;
                    opacity: 0;
                    transition: opacity 1s ease;
                }

                &.active {
                    height: 40%;

                    p {
                        opacity: 1;
                    }
                }
            }
        }

        .textiles {
            .textiles-img {
                display: flex;
                align-items: center;
                margin-bottom: 15px;
            }

            .textile {
                font-size: 24px !important;
            }

            .items .item p {
                font-size: small;
            }
        }
    }
    .item{
        display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-bottom: 10px;
    }
    .divider {
        width: 100%;
        height: 2px;
        background: #ccc;
        margin: 0 30px;
        transform: translateY(-50px);
    }
}
</style>
