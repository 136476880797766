<template>
    <div class="finance-container">
      <h1 class="title">碳金融</h1>
      <div class="finance-steps">
        <div
          v-for="(item, index) in financeItems"
          :key="index"
          class="finance-card animate__animated animate__fadeInUp"
          :style="{ animationDelay: `${index * 0.3}s` }"
          @mouseenter="handleMouseEnter(index)"
          @mouseleave="handleMouseLeave(index)"
        >
          <div class="finance-number">{{ index + 1 }}</div>
          <div class="finance-content">
            <h2 class="finance-title" :class="{ 'animate__animated animate__fadeIn': animatedIndex === index }">{{ item.title }}</h2>
            <p class="finance-description" :class="{ 'animate__animated animate__fadeIn': animatedIndex === index }">{{ item.description }}</p>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        financeItems: [
          { title: "质押（CCER）", description: "描述质押（CCER）的内容" },
          { title: "借碳交易", description: "描述借碳交易的内容" },
          { title: "配额质押", description: "描述配额质押的内容" }
        ],
        animatedIndex: null
      };
    },
    methods: {
      handleMouseEnter(index) {
        this.animatedIndex = index;
      },
      handleMouseLeave() {
        this.animatedIndex = null;
      }
    }
  };
  </script>
  
  <style scoped>
  @import 'animate.css';
  
  .finance-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    text-align: center;
  }
  
  .title {
    font-size: 2.5em;
    margin-bottom: 20px;
    color: #2c3e50;
  }
  
  .finance-steps {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .finance-card {
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin: 10px;
    padding: 20px;
    width: 250px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    position: relative;
    overflow: hidden;
    z-index: 1;
  }
  
  .finance-card::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(120deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0));
    transition: opacity 0.3s ease;
    opacity: 0;
    z-index: -1;
  }
  
  .finance-card:hover::before {
    opacity: 1;
  }
  
  .finance-card:hover {
    transform: translateY(-10px) scale(1.05) rotate(-1deg);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
  
  .finance-number {
    font-size: 1.5em;
    color: #3498db;
  }
  
  .finance-title {
    font-size: 1.25em;
    margin: 10px 0;
  }
  
  .finance-description {
    font-size: 1em;
    color: #7f8c8d;
  }
  </style>
  