<template>
  <div class="page main-page-modal" id="hiddenDiv">
    <main class="page-content" id="pc_about">
      <section class="mainwell asdlkal">
        <div class="container">
          <div class="row text-sm-left" style="position: relative;">
            <div class="zishiying_wenzi">
              <div class="chead" style="margin-bottom: 20px; display: flex; justify-content: space-between; flex-wrap: wrap;">
                <div>
                  <span class="i18n" name="introduction" style="font-size: 32px">上市介绍</span>
                  <img class="chead-rightIcon" :src="subscript" alt="Icon">
                </div>
                <img :src="dotdotUrl" alt="Dot" style="float: right" class="uiqwuiqw">
              </div>
              <!-- 添加标签区域 -->
              <div class="tags-container">
                <span class="tag">美股</span>
                <span class="tag">港股</span>
                <span class="tag">澳股</span>
              </div>
              <div class="line-right">
                <p class="link wow fadeInUp introductInfo" id="pc_introduct-info1">
                  澳大利亚的证券市场包含两个主要的证券交易所，历史悠久，并且在全球的资本市场中均占有极其重要的地位，分别是澳大利亚证券交易所 Australian Securities Exchange (ASX) 和澳大利亚国家交易所 National Stock Exchange of Australia (NSX)。目前 ASX 拥有超过 2300 家上市公司，融资渠道丰富、融资能力稳健、股票交易活跃。 澳大利亚的资本市场作为亚太区最主要且高度发达的资本市场之一，政治、经济环境稳定，法律制度健全、透明。吸引了来自世界各地的大量投资者，尤其是机构投资者，并拥有具备国际竞争力的商业环境
                </p>
                <p class="link wow fadeInUp introductInfo" id="pc_introduct-info2" style="margin-right: 0;"></p>
                <p class="link wow fadeInUp introductInfo" id="pc_introduct-info3" style="margin-right: 0;"></p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- 添加分界线 -->
      <div class="divider"></div>
      <section class="mainwell asdlkal">
        <div class="container">
          <div class="row text-sm-left" style="position: relative;">
            <div class="zishiying_wenzi">
              <div class="chead" style="margin-bottom: 40px; display: flex; justify-content: space-between; flex-wrap: wrap;">
                <div>
                  <span class="i18n" name="introduction" style="font-size: 32px">上市优势</span>
                  <img class="chead-rightIcon" :src="subscript" alt="Icon">
                </div>
                <img :src="dotdotUrl" alt="Dot" style="float: right" class="uiqwuiqw">
              </div>
              <div class="line-right">
                <p class="link wow fadeInUp introductInfo" id="pc_introduct-info1">
                  为扩大资金、加快发展、进入高流动性证券市场，很多企业会 选 择在澳大利亚的证券交易所首次公开发行证券(IPO)。无论 公司 发展到哪一阶段，公司董事和股东都有必要在做出決定， 如此重要的上市决定之前权衡利弊并了解 IPO 的整个过程。 相比较全球其他的证券交易所，澳大利亚的上市规则相对简洁， 可以大幅减少企业在 IPO 申请过程中所需的工作、投入的时间 和人力成本。更简洁的规则意味着更低的上市成本和更快的审批 速度。 本上市指导手册并非 IPO 行动计划方案，旨在解析 IPO 过程， 帮 助公司在了解所有相关信息后，再做出明智的上市决定。我 们能 给出的最好建议就是公司尽早向专业人士咨询。一旦决定 上市， 公司就要配备一支经验丰富的团队从旁辅佐，这一点至 关重要。
                </p>
                <p class="link wow fadeInUp introductInfo" id="pc_introduct-info2" style="margin-right: 0;"></p>
                <p class="link wow fadeInUp introductInfo" id="pc_introduct-info3" style="margin-right: 0;"></p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- 添加分界线 -->
      <div class="divider"></div>
      <section class="mainwell asdlkal">
        <div class="container">
          <div class="row text-sm-left" style="position: relative;">
            <div class="zishiying_wenzi">
              <div class="chead" style="margin-bottom: 40px; display: flex; justify-content: space-between; flex-wrap: wrap;">
                <div>
                  <span class="i18n" name="introduction" style="font-size: 32px">上市条件</span>
                  <img class="chead-rightIcon" :src="subscript" alt="Icon">
                </div>
                <img :src="dotdotUrl" alt="Dot" style="float: right" class="uiqwuiqw">
              </div>
              <div class="line-right">
                <p class="link wow fadeInUp introductInfo" id="pc_introduct-info1">
                  ASX 接受澳大利亚证监会（ASIC）的直接监管，所有企业在 ASX 上市都必须得到 ASIC 和 ASX 的上市批准和许可。《澳大 利亚证 券交易所上市规则》规定了企业在 ASX 上市需要满足 的最基本 的准入条件，企业必须符合法律规定的所有先决条件
                </p>
                <p class="link wow fadeInUp introductInfo" id="pc_introduct-info2" style="margin-right: 0;"></p>
                <p class="link wow fadeInUp introductInfo" id="pc_introduct-info3" style="margin-right: 0;"></p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  </div>
</template>

<script>
import subscript from "@/assets/subscript1.png";
import dotdotUrl from "@/assets/dot_dot.png";
export default {
  name: 'YunfengFundAbout',
  setup(){
    return {
      subscript,
      dotdotUrl
    }
  }
}
</script>

<style scoped>
body {
    font-family: 'Arial', sans-serif;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    background-color: #ffffff;
}

.mainwell {
    padding: 20px;
    background-color: #f9f9f9;
}

.zishiying_wenzi {
    max-width: 800px;
    margin: auto;
    text-align: left;
}

.line-right {
    border-left: 4px solid #779649;
    padding-left: 20px;
    margin-top: 20px;
    /* 添加动画 */
    animation: slideInUp 1s ease-out;
}

@keyframes slideInUp {
    0% {
        opacity: 0;
        transform: translateY(20px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.introductInfo {
    font-size: 18px;
    line-height: 1.6;
    margin-bottom: 20px;
    color: #333333;
}

@media (max-width: 768px) {
    .page-content {
        padding: 10px;
    }

    .mainwell {
        padding: 10px;
    }

    .zishiying_wenzi {
        max-width: 100%;
    }

    .chead {
        font-size: 28px;
    }

    .introductInfo {
        font-size: 16px;
    }
}

.tags-container {
    margin-top: 20px;
}

.tag {
    display: inline-block;
    padding: 10px 20px;
    margin: 5px;
    border-radius: 5px;
    background-color: #779649;
    color: #ffffff;
    text-align: center;
    cursor: pointer;
    font-size: 16px;
}

.tag:hover {
    background-color: #003399;
}

.divider {
    width: 50%;
    height: 1px;
    background-color: #ccc;
    margin: 0px auto; /* Center the divider */
}
</style>
