<template>
  <div :class="boxClass">
    <Finance></Finance>
    <Timeline></Timeline>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, computed, onMounted, onBeforeUnmount } from 'vue';
import Finance from '@/components/Home/finance.vue';
import Timeline from '@/components/Home/Timeline.vue';

export default defineComponent({
  name: 'IPO金融',
  components: {
    Finance,
    Timeline,
  },
  setup() {
    const isPhone = ref(window.innerWidth <= 768);
    
    // 计算属性，根据窗口大小确定类名
    const boxClass = computed(() => (isPhone.value ? 'model_box' : 'box'));
    
    const handleResize = () => {
      isPhone.value = window.innerWidth <= 768;
    };
    
    onMounted(() => {
      window.addEventListener('resize', handleResize);
    });
    
    onBeforeUnmount(() => {
      window.removeEventListener('resize', handleResize);
    });
    
    return {
      boxClass,
    };
  }
});
</script>

<style lang="scss" scoped>
.box {
  width: 100%;
  padding-top: 110px;
}

.model_box {
  width: 100%;
  padding-top: 60px;
}
</style>
