<template>
    <div class="service-container">
      <h1 class="title">服务</h1>
      <div class="service-steps">
        <div
          v-for="(item, index) in serviceItems"
          :key="index"
          class="service-card animate__animated animate__fadeInUp"
          :style="{ animationDelay: `${index * 0.3}s` }"
          @mouseenter="handleMouseEnter(index)"
          @mouseleave="handleMouseLeave(index)"
        >
          <div class="service-number">{{ index + 1 }}</div>
          <div class="service-content">
            <h2 class="service-title" :class="{ 'animate__animated animate__fadeIn': animatedIndex === index }">{{ item.title }}</h2>
            <p class="service-description" :class="{ 'animate__animated animate__fadeIn': animatedIndex === index }">{{ item.description }}</p>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        serviceItems: [
          { title: "碳资产开发", description: "描述碳资产开发的内容" },
          { title: "合作", description: "描述合作的内容" },
          { title: "培训", description: "描述培训的内容" },
          { title: "认证", description: "描述认证的内容" }
        ],
        animatedIndex: null
      };
    },
    methods: {
      handleMouseEnter(index) {
        this.animatedIndex = index;
      },
      handleMouseLeave() {
        this.animatedIndex = null;
      }
    }
  };
  </script>
  
  <style scoped>
  @import 'animate.css';
  
  .service-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    text-align: center;
  }
  
  .title {
    font-size: 2.5em;
    margin-bottom: 20px;
    color: #2c3e50;
  }
  
  .service-steps {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .service-card {
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin: 10px;
    padding: 20px;
    width: 250px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    position: relative;
    overflow: hidden;
    z-index: 1;
  }
  
  .service-card::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(120deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0));
    transition: opacity 0.3s ease;
    opacity: 0;
    z-index: -1;
  }
  
  .service-card:hover::before {
    opacity: 1;
  }
  
  .service-card:hover {
    transform: translateY(-10px) scale(1.05) rotate(-1deg);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
  
  .service-number {
    font-size: 1.5em;
    color: #3498db;
  }
  
  .service-title {
    font-size: 1.25em;
    margin: 10px 0;
  }
  
  .service-description {
    font-size: 1em;
    color: #7f8c8d;
  }
  </style>
  