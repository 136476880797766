<template>
  <div class="boxy_box">
    <div class="chead">
      <div class="chead-left">
        <span class="i18n" name="introduction">碳汇介绍</span>
        <img class="chead-rightIcon" :src="subscript" alt="Icon" />
      </div>
      <span class="chead-desc">交所IPO 精选成功案例</span>
    </div>
    <div>
      <div class="chead">
        <div class="chead-left">
          <span class="chead-desc" name="introduction">碳汇是什么意思？</span>
          <img class="chead-desc-rightIcon" :src="subscript" alt="Icon" />
        </div>
        <p class="description-text">
          碳元素会以不同的形态存在于自然界。碳源指的就是碳储库中向大气释放碳的过程、活动或机制，如毁林、煤炭燃烧发电等过程。<br /><br />
          而碳汇恰恰相反，是指通过种种措施吸收大气中的二氧化碳，从而减少温室气体在大气中浓度的过程、活动或机制。世界上主要的碳汇包括森林碳汇、草地碳汇、耕地碳汇、土壤碳汇及海洋碳汇等。
        </p>
      </div>
    </div>
  </div>
  <div class="boxy_box">
    <div class="about-dotted-line" ref="dottedLine"></div>
    <div class="chead">
      <div class="chead-left">
        <span class="i18n" name="introduction">资讯</span>
        <img class="chead-rightIcon" :src="subscript" alt="Icon" />
      </div>
      <span class="chead-desc">交所IPO 精选成功案例</span>
    </div>
    <div class="zx">
      <div class="z1x">
        <div
          class="draggable no-select"
          :style="{ transform: `translateY(${translateY}px)` }"
          @touchstart="startDrag"
          @touchmove="onDrag"
          @touchend="stopDrag"
        >
          <div v-for="image in images" :key="image.id" class="z1x_img no-select">
            <img :src="image.src" alt="" />
            <p class="image-description">{{ image.description }}</p>
          </div>
        </div>
      </div>
      <div class="z2x" ref="z2x">
        <ul>
          <li v-for="item in listItems" :key="item.id">
            <a>{{ item.title }}</a>
            <span class="z2x_span">{{ item.date }}</span>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div class="boxy_box">
      <div class="about-dotted-line" ref="dottedLine" style="margin: 20px 0px;"></div>
      <div class="chead">
        <div class="chead-left">
          <span class="i18n" name="introduction">碳排放权交易</span>
          <img class="chead-rightIcon" :src="subscript" alt="Icon">
        </div>
      </div>
      <div>
        <div
      class="chead"
      :style="{
        fontFamily: 'Arial, sans-serif',
        backgroundImage: `url(${bg21})`,
        color: 'rgb(44, 62, 80)',
        margin: '0px',
        padding: '0px'
      }"
    >
          <process></process>
        </div>
      </div>
    </div>
    <div class="boxy_box">
      <div class="about-dotted-line" ref="dottedLine" style="margin: 20px 0px;"></div>
      <div class="chead">
        <div class="chead-left">
          <span class="i18n" name="introduction">碳金融-质押（CCER）/借碳交易/配额质押</span>
          <img class="chead-rightIcon" :src="subscript" alt="Icon">
        </div>
      </div>
      <div>
        <div
      class="chead"
      :style="{
        fontFamily: 'Arial, sans-serif',
        backgroundImage: `url(${bg21})`,
        color: 'rgb(44, 62, 80)',
        margin: '0px',
        padding: '0px'
      }"
    >
          <CarbonFinance></CarbonFinance>
        </div>
      </div>
    </div>
    <div class="boxy_box">
      <div class="about-dotted-line" ref="dottedLine" style="margin: 20px 0px;"></div>
      <div class="chead">
        <div class="chead-left">
          <span class="i18n" name="introduction">服务</span>
          <img class="chead-rightIcon" :src="subscript" alt="Icon">
        </div>
      </div>
      <div>
        <div
      class="chead"
      :style="{
        fontFamily: 'Arial, sans-serif',
        backgroundImage: `url(${bg21})`,
        color: 'rgb(44, 62, 80)',
        margin: '0px',
        padding: '0px'
      }"
    >
          <Service></Service>
        </div>
      </div>
    </div>
    <div class="boxy_box">
      <div class="about-dotted-line" ref="dottedLine" style="margin: 20px 0px;"></div>
      <div class="chead">
        <div class="chead-left">
          <span class="i18n" name="introduction">碳资产开发</span>
          <img class="chead-rightIcon" :src="subscript" alt="Icon">
        </div>
      </div>
      <div>
        <div class="chead" style="font-family: Arial, sans-serif; background: rgb(236, 240, 241); color: rgb(44, 62, 80); margin: 0px; padding: 0px;">
          <development></development>
        </div>
      </div>
    </div>
    <div class="boxy_box">
      <div class="about-dotted-line" ref="dottedLine" style="margin: 20px 0px;"></div>
      <div class="chead">
        <div class="chead-left">
          <span class="i18n" name="introduction">业务（合作，培训，认证）</span>
          <img class="chead-rightIcon" :src="subscript" alt="Icon">
        </div>
      </div>
      <div>
        <div
      class="chead"
      :style="{
        fontFamily: 'Arial, sans-serif',
        backgroundImage: `url(${bg21})`,
        color: 'rgb(44, 62, 80)',
        margin: '0px',
        padding: '0px'
      }"
    >
          <Business></Business>
        </div>
      </div>
    </div>
</template>

<script lang="ts">
import  bg21  from '@/assets/bg1.jpg';
import { ref, onMounted, onBeforeUnmount } from 'vue';
import subscript from "@/assets/subscript1.png";
import image3 from '@/assets/image3.jpg';
  import process from '@/components/Home/process.vue'
import CarbonFinance from '@/components/Home/CarbonFinance.vue'
import Service from '@/components/Home/Service.vue'
import Business from '@/components/Home/Business.vue'
import development from '@/components/Home/development.vue'
export default {
  name: '碳汇介绍',
  components:{
    process,Business,development,CarbonFinance,Service
  },
  setup() {
    const z1x = ref<HTMLDivElement | null>(null);
    const z2x = ref<HTMLDivElement | null>(null);
    const dragging = ref(false);
    const startY = ref(0);
    const translateY = ref(0);
    const images = ref([
      { id: 1, src: image3, description: '中国绿色碳汇基金会出席“国新青年林”揭牌仪式暨2024年植树活动' },
      { id: 2, src: image3, description: '中国绿色碳汇基金会出席“国新青年林”揭牌仪式暨2024年植树活动' },
      // Add more images here
    ]);

    const listItems = ref([
      { id: 1, link: '#', title: '细读碳市场“年报”：活力稳步提升 为全球贡献“中国方案”', date: '24-07-25' },
      { id: 2, link: '#', title: '《赓续红色血脉，传播绿色理念》', date: '24-07-22' },
      { id: 3, link: '#', title: '“碳”路先行｜求解“碳中和”：煤的“七十二变”', date: '24-07-22' },
      { id: 4, link: '#', title: '中国加强森林碳汇建设促进实现碳中和目标', date: '24-07-21' },
      { id: 5, link: '#', title: '红色走读寻史迹，护林访谈促长制', date: '24-07-19' },
      // Add more list items here
    ]);

    const setEqualHeight = () => {
      if (z1x.value && z2x.value) {
        (z1x.value as HTMLDivElement).style.height = `${(z2x.value as HTMLDivElement).clientHeight}px`;
      }
    };

    const startDrag = (event: TouchEvent) => {
      dragging.value = true;
      startY.value = event.touches[0].clientY - translateY.value;
    };

    const onDrag = (event: TouchEvent) => {
      if (dragging.value) {
        translateY.value = event.touches[0].clientY - startY.value;
      }
    };

    const stopDrag = () => {
      dragging.value = false;
    };

    onMounted(() => {
      setEqualHeight();
      window.addEventListener('resize', setEqualHeight);
    });

    onBeforeUnmount(() => {
      window.removeEventListener('resize', setEqualHeight);
    });

    return {
      subscript,
      images,
      listItems,
      z1x,
      z2x,
      translateY,
      startDrag,
      onDrag,
      stopDrag,
      bg21
    };
  },
};
</script>

<style lang="scss" scoped>
.boxy_box {
  width: 90%;
  margin: 0 auto;
}

.z1x_img {
  width: 100%;
  height: auto;
  img {
    width: 100%;
    height: auto;
    user-select: none;
  }
}

.zx {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.z1x {
  overflow: hidden;
}

.z2x {
  ul {
    padding: 0;
    background: #f6f6f6;
    margin: 0;
  }
  li {
    position: relative;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: auto;
    margin-bottom: 10px;
    a {
      text-decoration: none;
      color: #000;
      font-size: 16px;
      display: block;
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: calc(100% - 40px);
    }
  }
  li:hover {
    background-color: #ccc;
  }
}

.chead {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.chead-left {
  display: flex;
  align-items: center;
}

.i18n {
  font-size: 24px;
}

.chead-desc {
  margin: 6px 0;
  color: #727677;
  font-size: 14px;
  font-family: PingFangSC-Light, PingFang SC;
  font-weight: 300;
}

.about-dotted-line {
  height: 1px;
  border-top: 1px dashed #abafb1;
  z-index: 1;
}

.description-text {
  font-size: 16px;
}

.image-description {
  font-size: 16px;
}

.chead-rightIcon, .chead-desc-rightIcon {
  width: 24px;
  height: 24px;
  margin-left: 10px;
}
</style>
