import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '@/views/HomeView.vue'
import Page2 from '@/components/Page2.vue'
import Page3 from '@/components/Page3.vue'
import Page4 from '@/components/Page4.vue'
import Page5 from '@/components/Page5.vue'
import Page6 from '@/components/Page6.vue'
import footers from '@/components/footer.vue'
import CompanyProfile from '@/components/Home/CompanyProfile.vue'
import Navbar from '@/components/Navbar.vue'
import Carousel from '@/components/Home/Carousel.vue'
import structure from '@/components/Home/structure.vue'
import mission from '@/components/Home/mission.vue'
import Appmission from '@/components/appHome/Appmission.vue'
import Appstructure from '@/components/appHome/Appstructure.vue'
import partner from '@/components/Home/partner.vue'
import Apppartner from '@/components/appHome/Apppartner.vue'
import Advantage from '@/components/Home/Advantage.vue'
import finance from '@/components/Home/finance.vue'
import Timeline from '@/components/Home/Timeline.vue'
import Carbonsink from '@/components/Home/Carbonsink.vue'
import AppCarbonsink from '@/components/appHome/appCarbonsink.vue'
import process from '@/components/Home/process.vue'
import CarbonFinance from '@/components/Home/CarbonFinance.vue'
import Service from '@/components/Home/Service.vue'
import Business from '@/components/Home/Business.vue'
import development from '@/components/Home/development.vue'
import EffectFormula from '@/components/Home/EffectFormula.vue'
import Company from '@/components/CompanyProfile.vue'
const routes: Array<RouteRecordRaw> = [
  {
    path: '/Home',
    name: '导航',
    component: Navbar,
    children: [
      {
        path: '/',
        name: '首页',
        component: HomeView,
      },
      {
        path: '/Company',
        name: '公司简介',
        component: Company,
      },
      {
        path: '/Page2',
        name: 'IPO金融',
        component: Page2
      },
      {
        path: '/Page3',
        name: '绿色金融',
        component: Page3
      },
      // {
      //   path: '/Page4',
      //   name: '绿色基金',
      //   component: Page4
      // },
      // {
      //   path: '/Page5',
      //   name: '数字金融/数字资产',
      //   component: Page5
      // },
      {
        path: '/Page6',
        name: '联系我们',
        component: Page6
      }
    ]
  },
  {
    path: '/CompanyProfile',
    name: '公司介绍',
    component: CompanyProfile
  },
  {
    path: '/Carousel',
    name: '轮播组件',
    component: Carousel
  },
  {
    path: '/structure',
    name: '专家介绍',
    component: structure
  },
  {
    path: '/mission',
    name: '十年磨一剑',
    component: mission
  },
  {
    path: '/Appmission',
    name: '十年磨一剑(APP)',
    component: Appmission
  },
  {
    path: '/Appstructure',
    name: '专家介绍(APP)',
    component: Appstructure
  },
  {
    path: '/footers',
    name: '底部菜单',
    component: footers
  },
  {
    path: '/partner',
    name: '合作伙伴',
    component: partner
  },
  {
    path: '/Apppartner',
    name: '合作伙伴(APP)',
    component: Apppartner
  },
  {
    path: '/Advantage',
    name: '我们的优势',
    component: Advantage
  },
  {
    path: '/finance',
    name: 'IPO 金融',
    component: finance
  },
  {
    path: '/Timeline',
    name: '时间轴',
    component: Timeline
  },
  {
    path:'/Carbonsink',
    name:'碳汇介绍',
    component: Carbonsink
  },
  {
    path:'/AppCarbonsink',
    name:'碳汇介绍(APP)',
    component: AppCarbonsink
  },
  {
    path:'/process',
    name:'流程',
    component: process
  },
  {
    path: '/CarbonFinance',
    name: '碳金融',
    component: CarbonFinance
  },
  {
    path: '/Service',
    name: '服务',
    component: Service
  },
  {
    path: '/Business',
    name: '业务',
    component: Business
  },
  {
    path: '/development',
    name: '开发',
    component: development
  },
  {
    path: '/EffectFormula',
    name: '公式',
    component: EffectFormula
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
