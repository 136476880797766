<template>
  <div class="process-container">
    <h1 class="title">林业碳汇预售流程</h1>
    <div class="process-steps">
      <div
        v-for="(step, index) in steps"
        :key="index"
        class="step-card animate__animated animate__fadeInUp"
        :style="{ animationDelay: `${index * 0.3}s` }"
        @mouseenter="handleMouseEnter(index)"
        @mouseleave="handleMouseLeave(index)"
      >
        <div class="step-number">{{ index + 1 }}</div>
        <div class="step-content">
          <h2 class="step-title" :class="{ 'animate__animated animate__fadeIn': animatedIndex === index }">{{ step.title }}</h2>
          <p class="step-description" :class="{ 'animate__animated animate__fadeIn': animatedIndex === index }">{{ step.description }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      steps: [
        { title: "开发", description: "签订开发协议" },
        { title: "文件编写", description: "PDD报告编写" },
        { title: "审定/注册", description: "第三方机构审定" },
        { title: "实施/监测", description: "" },
        { title: "核查/核证", description: "第三方机构核证" },
        { title: "预售", description: "" },
        { title: "买入", description: "有约定价格进行购买" },
        { title: "签发", description: "减排量签发后放的对应的CCER直接由碳交易所分配至政府/企业账户" }
      ],
      animatedIndex: null
    };
  },
  methods: {
    handleMouseEnter(index) {
      this.animatedIndex = index;
    },
    handleMouseLeave() {
      this.animatedIndex = null;
    }
  }
};
</script>

<style scoped>
@import 'animate.css';

.process-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  text-align: center;
}

.title {
  font-size: 2.5em;
  margin-bottom: 20px;
  color: #2c3e50;
}

.process-steps {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.step-card {
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 10px;
  padding: 20px;
  width: 250px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.step-card::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(120deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0));
  transition: opacity 0.3s ease;
  opacity: 0;
  z-index: -1;
}

.step-card:hover::before {
  opacity: 1;
}

.step-card:hover {
  transform: translateY(-10px) scale(1.05) rotate(-1deg);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.step-number {
  font-size: 1.5em;
  color: #3498db;
}

.step-title {
  font-size: 1.25em;
  margin: 10px 0;
}

.step-description {
  font-size: 1em;
  color: #7f8c8d;
}

/* Mobile Styles */
@media (max-width: 768px) {
  .title {
    font-size: 1.8em;
  }
  
  .step-card {
    width: 100%;
    margin: 10px 0;
  }

  .process-steps {
    /* flex-direction: column;
    align-items: center; */
  }
}
</style>
