<template>
    <div class="boxy_box">
      <div class="chead">
        <div class="chead-left">
          <span class="i18n" name="introduction">碳汇介绍</span>
          <img class="chead-rightIcon" :src="subscript" alt="Icon">
        </div>
        <span class="chead-desc">交所IPO 精选成功案例</span>
      </div>
      <div>
        <div class="chead">
          <div class="chead-left">
            <span class="chead-desc" name="introduction">碳汇是什么意思？</span>
            <img class="chead-desc-rightIcon" :src="subscript" alt="Icon">
          </div>
          <p style="font-size: 16px;">
            碳元素会以不同的形态存在于自然界。碳源指的就是碳储库中向大气释放碳的过程、活动或机制，如毁林、煤炭燃烧发电等过程。<br><br>
            而碳汇恰恰相反，是指通过种种措施吸收大气中的二氧化碳，从而减少温室气体在大气中浓度的过程、活动或机制。世界上主要的碳汇包括森林碳汇、草地碳汇、耕地碳汇、土壤碳汇及海洋碳汇等。
          </p>
        </div>
      </div>
    </div>
    <div class="boxy_box">
      <div class="about-dotted-line" ref="dottedLine"></div>
      <div class="chead">
        <div class="chead-left">
          <span class="i18n" name="introduction">资讯</span>
          <img class="chead-rightIcon" :src="subscript" alt="Icon">
        </div>
        <span class="chead-desc">交所IPO 精选成功案例</span>
      </div>
      <div class="zx">
        <div class="z1x" ref="z1x">
          <div 
            class="draggable no-select" 
            :style="{ transform: `translateY(${translateY}px)` }" 
            @mousedown="startDrag" 
            @mousemove="onDrag" 
            @mouseup="stopDrag" 
            @mouseleave="stopDrag"
          >
            <div v-for="image in images" :key="image.id" class="z1x_img no-select">
              <img :src="image.src" alt="" draggable="false"/>
              <p style="font-size: 16px;">{{ image.description }}</p>
            </div>
          </div>
        </div>
        <div class="z2x" ref="z2x">
          <ul>
            <li v-for="item in listItems" :key="item.id">
              <a>{{ item.title }}</a>
              <span class="z2x_span">{{ item.date }}</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="boxy_box">
      <div class="about-dotted-line" ref="dottedLine" style="margin: 20px 0px;"></div>
      <div class="chead">
        <div class="chead-left">
          <span class="i18n" name="introduction">碳排放权交易</span>
          <img class="chead-rightIcon" :src="subscript" alt="Icon">
        </div>
      </div>
      <div>
        <div class="chead" style="height: 70vh;font-family: 'Arial', sans-serif;
  background: #ecf0f1;
  color: #2c3e50;
  margin: 0;
  padding: 0;">
          <process></process>
        </div>
      </div>
    </div>
    <div class="boxy_box">
      <div class="about-dotted-line" ref="dottedLine" style="margin: 20px 0px;"></div>
      <div class="chead">
        <div class="chead-left">
          <span class="i18n" name="introduction">碳金融-质押（CCER）/借碳交易/配额质押</span>
          <img class="chead-rightIcon" :src="subscript" alt="Icon">
        </div>
      </div>
      <div>
        <div class="chead" style="font-family: Arial, sans-serif; background: rgb(236, 240, 241); color: rgb(44, 62, 80); margin: 0px; padding: 0px;">
          <CarbonFinance></CarbonFinance>
        </div>
      </div>
    </div>
    <div class="boxy_box">
      <div class="about-dotted-line" ref="dottedLine" style="margin: 20px 0px;"></div>
      <div class="chead">
        <div class="chead-left">
          <span class="i18n" name="introduction">服务</span>
          <img class="chead-rightIcon" :src="subscript" alt="Icon">
        </div>
      </div>
      <div>
        <div class="chead" style="font-family: Arial, sans-serif; background: rgb(236, 240, 241); color: rgb(44, 62, 80); margin: 0px; padding: 0px;">
          <Service></Service>
        </div>
      </div>
    </div>
    <div class="boxy_box">
      <div class="about-dotted-line" ref="dottedLine" style="margin: 20px 0px;"></div>
      <div class="chead">
        <div class="chead-left">
          <span class="i18n" name="introduction">碳资产开发</span>
          <img class="chead-rightIcon" :src="subscript" alt="Icon">
        </div>
      </div>
      <div>
        <div class="chead" style="font-family: Arial, sans-serif; background: rgb(236, 240, 241); color: rgb(44, 62, 80); margin: 0px; padding: 0px;">
          <development></development>
        </div>
      </div>
    </div>
    <div class="boxy_box">
      <div class="about-dotted-line" ref="dottedLine" style="margin: 20px 0px;"></div>
      <div class="chead">
        <div class="chead-left">
          <span class="i18n" name="introduction">业务（合作，培训，认证）</span>
          <img class="chead-rightIcon" :src="subscript" alt="Icon">
        </div>
      </div>
      <div>
        <div class="chead" style="font-family: Arial, sans-serif; background: rgb(236, 240, 241); color: rgb(44, 62, 80); margin: 0px; padding: 0px;">
          <Business></Business>
        </div>
      </div>
    </div>
  </template>
  
  <script lang="ts">
  import { ref, onMounted, onBeforeUnmount } from 'vue';
  import process from '@/components/Home/process.vue'
import subscript from "@/assets/subscript1.png";
import image3 from '@/assets/image3.jpg';
import CarbonFinance from '@/components/Home/CarbonFinance.vue'
import Service from '@/components/Home/Service.vue'
import Business from '@/components/Home/Business.vue'
import development from '@/components/Home/development.vue'
export default {
  name: '碳汇介绍',
  components: {
    process,CarbonFinance,Service,Business,development
    },
  setup() {
    const z1x = ref<HTMLDivElement | null>(null);
    const z2x = ref<HTMLDivElement | null>(null);
    const dragging = ref(false);
    const startY = ref(0);
    const translateY = ref(0);
    const isPhone = ref(window.innerWidth <= 768);
    const images = ref([
      { id: 1, src: image3, description: '中国绿色碳汇基金会出席“国新青年林”揭牌仪式暨2024年植树活动' },
      { id: 2, src: image3, description: '中国绿色碳汇基金会出席“国新青年林”揭牌仪式暨2024年植树活动' },
      // Add more images here
    ]);

    const listItems = ref([
      { id: 1, link: '#', title: '细读碳市场“年报”：活力稳步提升 为全球贡献“中国方案”', date: '24-07-25' },
      { id: 2, link: '#', title: '《赓续红色血脉，传播绿色理念》', date: '24-07-22' },
      { id: 3, link: '#', title: '“碳”路先行｜求解“碳中和”：煤的“七十二变”', date: '24-07-22' },
      { id: 4, link: '#', title: '中国加强森林碳汇建设促进实现碳中和目标', date: '24-07-21' },
      { id: 5, link: '#', title: '红色走读寻史迹，护林访谈促长制', date: '24-07-19' },
      // Add more list items here
    ]);

    const setEqualHeight = () => {
      if (z1x.value && z2x.value) {
        (z1x.value as HTMLDivElement).style.height = `${(z2x.value as HTMLDivElement).clientHeight}px`;
      }
    };

    const startDrag = (event: MouseEvent) => {
      dragging.value = true;
      startY.value = event.clientY - translateY.value;
    };

    const onDrag = (event: MouseEvent) => {
      if (dragging.value) {
        translateY.value = event.clientY - startY.value;
      }
    };

    const stopDrag = () => {
      dragging.value = false;
    };

    onMounted(() => {
      setEqualHeight();
      window.addEventListener('resize', setEqualHeight);
      window.addEventListener('resize', () => {
                isPhone.value = window.innerWidth <= 768;
            });
    });

    onBeforeUnmount(() => {
      window.removeEventListener('resize', setEqualHeight);
    });

    return {
      subscript,
      images,
      listItems,
      z1x,
      z2x,
      translateY,
      startDrag,
      onDrag,
      stopDrag,
      isPhone
    };
  }
};
  </script>
  
  <style lang="scss" scoped>
  @import 'animate.css';
  .boxy_box {
    width: 80%;
  }
  .z1x_img {
    width: 100%;
    height: 30%;
    img {
      width: 100%;
      height: auto;
      user-select: none;
    }
  }
  .zx {
    width: 100%;
    display: flex;
    justify-content: space-around;
  }
  .z1x {
    width: 20%;
    overflow: hidden;
  }
  .z2x {
    width: 75%;
  }
  .chead {
    margin-bottom: 40px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    flex-direction: column;
  }
  .chead-left {
    display: flex;
    align-items: center;
  }
  .i18n {
    font-size: 32px;
  }
  .chead-desc {
    margin: 12px 0px;
    color: #727677;
    font-size: 16px;
    font-family: PingFangSC-Light, PingFang SC;
    font-weight: 300;
  }
  .about-dotted-line {
    height: 1px;
    border-top: 1px dashed #abafb1;
    z-index: 1;
  }
  .chead-desc-rightIcon {
    height: 15px;
    width: auto;
  }
  ul {
    list-style: none;
    padding: 10px;
    background: #f6f6f6;
  }
  li {
    position: relative;
    padding-left: 24px;
    height: 50px;
    display: flex;
    justify-content: space-between;
    padding: 0px 20px;
    margin-bottom: 5px;
  }
  li:hover {
    background-color: #ccc;
  }
  li:after {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 16px;
    height: 16px;
    background: url('data:image/svg+xml;utf8,<svg t="1721883458012" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2030" width="16" height="16"><path d="M293.7 635.7H643V592H293.7v43.7z m291.1-247.4H293.7V432h291.1v-43.7z m320.1-203.7v43.7l-43.6-43.6v1.2L816.4 141h1.1l-43.4-43.4 116.3-0.3h-684v87.3h-87.3v742.2h698.5v-87.3h87.3V184.6z m-654.8-43.7h508.3l-6.1 6.1 37.5 37.5H250.1v-43.6z m523.8 742.2H162.7V228.2h495l-4.6 4.6 120.8 120.8v529.5z m43.7-567.6L686.7 184.9l130.8-0.3v130.9z m43.7 480.3h-43.7V212.4l37.5 37.5 6.1-6.1v552z m-567.6-262H643v-43.7H293.7v43.7z m0 203.8H643v-43.7H293.7v43.7z" p-id="2031"></path></svg>') no-repeat center center;
    background-size: 16px 16px;
  }
  a {
    text-decoration: none;
    color: #000;
    font-size: 18px;
    text-align: left;
    height: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
  }
  .z2x_span {
    margin-left: 8px;
    font-size: 18px;
    text-align: right;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
  }
  .draggable {
    cursor: grab;
  }
  .draggable:active {
    cursor: grabbing;
  }
  .no-select {
    user-select: none;
  }
  </style>
  