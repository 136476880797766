import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';
import * as ElementPlusIconsVue from '@element-plus/icons-vue';
import VueLazyload from 'vue-lazyload';
// 创建Vue应用实例
const app = createApp(App);

// 使用store, router和ElementPlus插件
app.use(store)
   .use(router)
   .use(ElementPlus)
   .use(VueLazyload);;

// 注册ElementPlus图标组件
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component);
}

// 挂载应用实例到DOM
app.mount('#app');
