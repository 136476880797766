<template>
  <div :class="boxClass">
    <Carbonsink v-if="!isPhone"></Carbonsink>
    <AppCarbonsink v-else></AppCarbonsink>
  </div>
</template>

<script>import { defineComponent, ref, computed, onMounted, onBeforeUnmount } from 'vue';
import AppCarbonsink from '@/components/appHome/appCarbonsink.vue'
import Carbonsink from '@/components/Home/Carbonsink.vue'
export default {
  name:'绿色金融',
  components:{
    Carbonsink,AppCarbonsink
  },
  setup() {
    const isPhone = ref(window.innerWidth <= 768);
    
    // 计算属性，根据窗口大小确定类名
    const boxClass = computed(() => (isPhone.value ? 'model_box' : 'box'));
    
    const handleResize = () => {
      isPhone.value = window.innerWidth <= 768;
    };
    
    onMounted(() => {
      window.addEventListener('resize', handleResize);
    });
    
    onBeforeUnmount(() => {
      window.removeEventListener('resize', handleResize);
    });
    return{
      isPhone,boxClass,
    }
  }
};
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html {
  overflow-x: hidden;
}
.box{
  margin-top: 110px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.model_box {
  width: 100%;
  padding-top: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
