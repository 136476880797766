<template>
    <div class="structure-box">
        <div class="bg"></div>
        <div class="structure-text">
            <div class="text-box">
                <div class="textiles">
                    <img :src="listyle" class="icon" />
                    <div class="textile">Lin Jian dong</div>
                    <div class="items">
                        <div v-for="(item, index) in overlays" :key="index" class="item">
                            <p>{{ item.text }}</p>
                            <p v-if="item.Position" style="color: #221d1d;font-size: 23px;">{{ item.Position }}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="image-container" @mouseover="hover = true" @mouseleave="hover = false">
                <img :src="people" />
                <div class="overlay" :class="{ active: hover }">
                    <p>林建东</p>
                </div>
            </div>
        </div>
        <div style="width: 100%;height: 2px;background: #ccc;margin: 0px 30px;transform: translateY(-50px);"></div>
    </div>
</template>

<script>
import { ref } from "vue";
import people from "@/assets/people1.jpg";
import listyle from "@/assets/copy4.png";

export default {
    setup() {
        const hover = ref(false);
        const overlays = ref([
            {
                text: '沙特阿拉伯金属及矿业证券交易所',
                Position: '股东'
            },
            {
                text: '门萨会员俱乐部会员',
                Position: null
            },
            {
                text: '澳大利亚国家证券交易所',
                Position: '股东'
            },
            {
                text: '香港碳排放交易所',
                Position: '总监（2021年）'
            },
            {
                text: '香港记忆学总会创始人',
                Position: null
            },
        ]);
        return {
            people,
            hover,
            listyle,
            overlays
        };
    },
};
</script>

<style lang="scss" scoped>
.structure-box {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;

    .bg {
        width: 100%;
        height: 300px;
        background: #000;
    }

    .structure-text {
        width: 70%;
        display: flex;
        flex-wrap: wrap;
        align-content: center;
        justify-content: space-between;
        background: #f7f7f7;
        transform: translateY(-30%);
        padding: 20px;
        border-radius: 20px;

        .text-box {
            width: 70%;

            .textiles {
                height: 100%;
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                align-content: space-around;

                .icon {
                    margin-right: 5px;
                    height: auto;
                }

                .textile {
                    font: 600 44px / 1.27 Merriweather, GeorgiaCustom;
                    margin-bottom: 8px;
                    word-break: break-word;
                }

                .items {
                    width: 100%;

                    .item {
                        margin-bottom: 10px;
                        display: flex;

                        p {
                            margin: 10px;
                            font-size: medium;
                            font-family: emoji;
                            line-height: 30px;
                        }
                    }
                }
            }
        }

        .image-container {
            position: relative;
            width: 30%;
            overflow: hidden;

            img {
                position: absolute;
                display: block;
                width: 100%;
                transition: transform 0.3s ease, opacity 0.3s ease;
                -webkit-user-select: none;
                -moz-user-select: none;
                user-select: none;
                bottom: 0;
                object-fit: contain;
            }

            img:hover {
                opacity: 1;
                transform: scale(1.2);
            }

            .overlay {
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 0;
                background: rgba(0, 0, 0, 0.4);
                transition: height 1s ease;
                display: flex;
                align-items: center;
                padding-left: 20px;

                p {
                    color: #fff;
                    border-left: 10px solid #779649;
                    font-size: 52px;
                    padding-left: 20px;
                    font-family: fangsong;
                    font-weight: 600;
                    margin: 0;
                    opacity: 0;
                    transition: opacity 1s ease;
                }
            }

            .overlay.active {
                height: 40%;

                p {
                    opacity: 1;
                }
            }
        }
    }
}
</style>