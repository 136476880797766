<template>
  <nav :class="['navbar', isPhone ? 'phone-navbar' : 'desktop-navbar']">
    <div class="logo" v-if="!isPhone" style="height: 100px;">
      <img src="@/assets/logo.png" alt="Logo" style="height: 100px;" />
    </div>
    <div class="logo" v-else >
      <img src="@/assets/logo.png" alt="Logo"  />
    </div>
    <div v-if="isPhone" class="menu-icon" @click="toggleMenu">
      <!-- <img src="@/assets/menu-icon.png" alt="Menu Icon" /> -->
      <div tabindex="0" class="plusButton">
        <svg class="plusIcon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30">
          <g mask="url(#mask0_21_345)">
            <path d="M13.75 23.75V16.25H6.25V13.75H13.75V6.25H16.25V13.75H23.75V16.25H16.25V23.75H13.75Z"></path>
          </g>
        </svg>
      </div>
    </div>
    <ul class="nav-links" :class="{ open: isMenuOpen || !isPhone }">
      <li
        v-for="(route, index) in routes"
        :key="index"
        :class="{ active: $route.path === route.path }"
      >
        <router-link class="a" :to="route.path" @click="navigate(index)">
          {{ route.name }}
        </router-link>
      </li>
    </ul>
    <div class="indicator" ref="indicator" :style="indicatorStyle"></div>
  </nav>
  <router-view></router-view>
</template>

<script lang="ts">
import { defineComponent, computed, ref, watch, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';

export default defineComponent({
  name: 'Navbar',
  setup() {
    const isPhone = ref(window.innerWidth <= 768);
    const isMenuOpen = ref(false);
    const router = useRouter();
    const route = useRoute();
    const indicator = ref<HTMLElement | null>(null);

    const routes = computed(() => {
      const mainRoute = router.options.routes.find(r => r.path === '/Home');
      return mainRoute?.children || [];
    });

    const activeIndex = computed(() =>
      routes.value.findIndex((r) => r.path === route.path)
    );

    const indicatorStyle = computed(() => ({
      left: `${activeIndex.value * 100}px`,
    }));

    const animateIndicator = (fromIndex: number, toIndex: number) => {
      if (!indicator.value) return;

      const direction = toIndex > fromIndex ? 1 : -1;
      const distance = Math.abs(toIndex - fromIndex) * 100;
      const initialLeft = fromIndex * 100;
      let currentLeft = initialLeft;

      const moveIndicator = () => {
        if (
          (direction === 1 && currentLeft < toIndex * 100) ||
          (direction === -1 && currentLeft > toIndex * 100)
        ) {
          currentLeft += direction;
          indicator.value!.style.left = `${currentLeft}px`;
          requestAnimationFrame(moveIndicator);
        }
      };

      moveIndicator();
    };

    const navigate = (index: number) => {
      const fromIndex = activeIndex.value;
      animateIndicator(fromIndex, index);
      if (isPhone.value) {
        isMenuOpen.value = false;
      }
    };

    const toggleMenu = () => {
      isMenuOpen.value = !isMenuOpen.value;
    };

    watch(
      () => route.path,
      (newPath, oldPath) => {
        const toIndex = routes.value.findIndex((r) => r.path === newPath);
        const fromIndex = routes.value.findIndex((r) => r.path === oldPath);
        animateIndicator(fromIndex, toIndex);
      }
    );

    onMounted(() => {
      if (indicator.value) {
        indicator.value.style.left = `${activeIndex.value * 100}px`;
      }

      window.addEventListener('resize', () => {
        isPhone.value = window.innerWidth <= 768;
        if (!isPhone.value) {
          isMenuOpen.value = false;
        }
      });
    });

    return {
      routes,
      indicatorStyle,
      navigate,
      indicator,
      isPhone,
      isMenuOpen,
      toggleMenu,
    };
  },
});
</script>

<style scoped>
.navbar {
  height: 90px;
  position: fixed;
  top: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: rgba(0, 0, 0, 0.25);
  padding: 10px;
  z-index: 1000;
}

.logo img {
  height: 40px;
}

.menu-icon {
  display: none;
  cursor: pointer;
  z-index: 1100;
}


.nav-links {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  flex: 1;
  justify-content: center;
  align-items: center;
  transition: transform 0.3s ease;
}

.nav-links li {
  font-size: 18px;
  font-weight: 400;
  color: rgba(175, 175, 179, 1);
  min-width: 79px;
  text-align: center;
  padding: 0 30px;
  box-sizing: border-box;
  white-space: nowrap;
  position: relative;
}

.nav-links a {
  color: white;
  text-decoration: none;
  font: inherit;
  position: relative;
}

.nav-links .active a {
  font-weight: bold;
}

.nav-links li::after {
  content: '';
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -20px;
  width: 5px;
  height: 5px;
  border: 2px solid #fff;
  border-radius: 50%;
  transform-origin: center;
  transform: scale(0);
  transition: transform 0.3s ease, border-width 0.3s ease;
  margin-top: 5px;
}

.nav-links li.active::after {
  transform: scale(1) translateX(-50%);
}

@media (max-width: 768px) {
  .navbar {
    height: auto;
    position: fixed;
    top: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: rgba(0, 0, 0, 0.05);
    padding: 10px;
    z-index: 1000;
  }

  .menu-icon {
    display: block;
    right: 10px;
    position: fixed;
    z-index: 1100;
  }

  .nav-links {
    position: fixed;
    top: 60px;
    right: 0;
    background-color: rgb(0, 0, 0);
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 60px 20px;
    transform: translateX(100%);
    transition: transform 0.3s ease;
  }

  .nav-links.open {
    transform: translateX(0);
  }

  .nav-links li {
    width: 100%;
    text-align: left;
    padding: 15px 0;
  }

  .nav-links li::after {
    display: none;
  }
}

/* Note that you only needs to edit the config to customize the button! */

.plusButton {
  /* Config start */
  --plus_sideLength: 2.5rem;
  --plus_topRightTriangleSideLength: 0.9rem;
  /* Config end */
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid white;
  width: var(--plus_sideLength);
  height: var(--plus_sideLength);
  background-color: #000000;
  overflow: hidden;
}

.plusButton::before {
  position: absolute;
  content: "";
  top: 0;
  right: 0;
  width: 0;
  height: 0;
  border-width: 0 var(--plus_topRightTriangleSideLength) var(--plus_topRightTriangleSideLength) 0;
  border-style: solid;
  border-color: transparent white transparent transparent;
  transition-timing-function: ease-in-out;
  transition-duration: 0.2s;
}

.plusButton:hover {
  cursor: pointer;
}

.plusButton:hover::before {
  --plus_topRightTriangleSideLength: calc(var(--plus_sideLength) * 2);
}

.plusButton:focus-visible::before {
  --plus_topRightTriangleSideLength: calc(var(--plus_sideLength) * 2);
}

.plusButton>.plusIcon {
  fill: white;
  width: calc(var(--plus_sideLength) * 0.7);
  height: calc(var(--plus_sideLength) * 0.7);
  z-index: 1;
  transition-timing-function: ease-in-out;
  transition-duration: 0.2s;
}

.plusButton:hover>.plusIcon {
  fill: black;
  transform: rotate(180deg);
}

.plusButton:focus-visible>.plusIcon {
  fill: black;
  transform: rotate(180deg);
}
</style>
