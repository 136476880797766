<template>
    <div class="timeline-container" 
         :style="{ backgroundImage: `url(${background5})` }" 
         @mousedown="startDrag" 
         @mousemove="onDrag" 
         @mouseup="endDrag" 
         @mouseleave="endDrag"
         @touchstart="startDrag"
         @touchmove="onDrag"
         @touchend="endDrag">
      <div class="chead">
        <div class="chead-left">
          <span class="i18n" name="introduction">成功案例</span>
          <img class="chead-rightIcon" :src="subscript" alt="Icon">
        </div>
        <span class="chead-desc">交所IPO 精选成功案例</span>
      </div>
      <div class="timeline" ref="timeline">
        <div v-for="(event, index) in events" :key="index" class="about-time-entry">
          <div :class="['about-dotted-line-left', { 'alternate-style': index % 2 === 1 }]">
            <div class="about-time-title">{{ event.title }}</div>
            <div class="about-time-content">{{ event.content }}</div>
          </div>
          <div class="about-time-info">
            <img :src="listyle" alt="" class="icon-copy-bottom" />
            <span class="about-time-time">{{ event.year }}</span>
          </div>
        </div>
        <div class="about-dotted-line" ref="dottedLine"></div>
      </div>
    </div>
  </template>
  
  <script>
  import listyle from "@/assets/copy4.png";
  import background5 from "@/assets/background5.png";
  import subscript from "@/assets/subscript1.png";
  
  export default {
    name: 'Home',
    data() {
      return {
        listyle,
        background5,
        subscript,
        events: [
  { 
    title: "USD Fund IV", 
    content: "协助兴盛国际完成在 ASX 成功上市，成为中国私有矿业企业在 ASX 成功上市的首个成功案例。",
    year: "2009" 
  },
  { 
    title: "RMB Fund V", 
    content: "协助雀友完成在澳大利亚 ASX 首次公开发行。",
    year: "2009" 
  },
  { 
    title: "RMB Fund IV", 
    content: "协助中国教育企业笑笑教育在 ASX 成功上市，成为中国教育企业在澳大利亚上市的首个成功案例。",
    year: "2010" 
  },
  { 
    title: "USD Fund III", 
    content: "协助中国物流公司荣泰国际完成在 ASX 成功上市。",
    year: "2010" 
  },
  { 
    title: "RMB Fund III", 
    content: "协助中国黑金环球控股集团完成在 ASX 成功上市，成为 ASX 融资规模最大的里程碑案例。",
    year: "2011" 
  },
  { 
    title: "RMB Fund II", 
    content: "协助中国农业公司东方现代农业完成在 ASX 成功上市。",
    year: "2015" 
  },
  { 
    title: "USD Fund II", 
    content: "协助 Mako Energy 以借壳上市方式成功登陆 ASX 并完成融资提供法律意见。",
    year: "2015" 
  },
  { 
    title: "RMB Fund I", 
    content: "协助天津鸿发投资集团完成在 ASX 的首次公开发行，成为天津首家在澳大利亚上市的企业。",
    year: "2016" 
  },
  { 
    title: "USD Fund I", 
    content: "协助中国乳业集团在 ASX 成功上市，成为乳业巨头的上市案例。",
    year: "2016" 
  },
  { 
    title: "RMB Fund VI", 
    content: "协助首家跨国人力资源服务提供商建城国际集团在 ASX 成功上市。",
    year: "2016" 
  },
  { 
    title: "RMB Fund VII", 
    content: "协助中国房地产开发商博源控股在 ASX 成功上市，开创了中资背景房地产开发企业在澳大利亚成功上市的先河。",
    year: "2016" 
  },
  { 
    title: "USD Fund VII", 
    content: "协助新疆矿业企业Emperor Range Group Ltd 在 ASX 成功上市，成为首家在澳大利亚上市的新疆企业。",
    year: "2017" 
  },
  { 
    title: "RMB Fund VIII", 
    content: "协助中国有机农产品企业家家富现代农业在 ASX 成功上市，成为有机农产品企业在澳大利亚成功上市的首个案例。",
    year: "2017" 
  },
  { 
    title: "USD Fund VIII", 
    content: "协助中国最大纺织品制造商及出口商“绅花纺织”在 ASX 上市，并提供上市合规操作和上市后的法律合规咨询。",
    year: "2018" 
  },
  { 
    title: "RMB Fund IX", 
    content: "协助健康保健用品企业 Smile Inclusive Limited 成功登陆 ASX。",
    year: "2018" 
  },
  { 
    title: "USD Fund IX", 
    content: "协助澳大利亚营养产品公司 Star Combo Pharma Ltd 成功登陆 ASX。",
    year: "2018" 
  },
  { 
    title: "RMB Fund X", 
    content: "协助矿业资源企业 Montreal Royal Resources Limite 成功登陆 ASX。",
    year: "2019" 
  },
  { 
    title: "USD Fund X", 
    content: "协助零售商 Mediland Pharm Limited 成功登陆 ASX，该公司为在澳大利亚境内旅游的中国游客提供购物服务。",
    year: "2019" 
  },
  { 
    title: "RMB Fund XI", 
    content: "协助农业生物技术科技公司 Terragen Holdings Limited 成功登陆 ASX。",
    year: "2019" 
  },
  { 
    title: "USD Fund XI", 
    content: "协助无人机安全技术公司 ParaZero Drone Safety Solutions 通过买壳上市方式收购 Delta Drone International Limited，并成功登陆 ASX。",
    year: "2020" 
  },
  { 
    title: "RMB Fund XII", 
    content: "协助 R3D Resources 公司完成 ASX 买壳上市。",
    year: "2021" 
  },
  { 
    title: "USD Fund XII", 
    content: "协助 Barton Gold 巴顿黄金集团成功登陆 ASX。",
    year: "2021" 
  }
],
        isDragging: false,
        startX: 0,
        startScrollLeft: 0
      };
    },
    mounted() {
      this.setDottedLineWidth();
      window.addEventListener('resize', this.setDottedLineWidth);
    },
    beforeDestroy() {
      window.removeEventListener('resize', this.setDottedLineWidth);
    },
    methods: {
      startDrag(e) {
        this.isDragging = true;
        this.startX = (e.pageX || e.touches[0].pageX) - this.$refs.timeline.getBoundingClientRect().left;
        this.startScrollLeft = this.$refs.timeline.scrollLeft;
        document.body.style.userSelect = 'none';
      },
      onDrag(e) {
        if (!this.isDragging) return;
        e.preventDefault();
        const x = (e.pageX || e.touches[0].pageX) - this.$refs.timeline.getBoundingClientRect().left;
        const walk = (x - this.startX) * 2;
        this.$refs.timeline.scrollLeft = this.startScrollLeft - walk;
      },
      endDrag() {
        this.isDragging = false;
        document.body.style.userSelect = '';
      },
      setDottedLineWidth() {
        const timelineWidth = this.$refs.timeline.scrollWidth;
        this.$refs.dottedLine.style.width = `${timelineWidth}px`;
      }
    }
  };
  </script>
  
  <style scoped>
  body {
    font-family: 'Arial', sans-serif;
    margin: 0;
    padding: 0;
    background-color: #ffffff;
  }
  
  .timeline-container {
    white-space: nowrap;
    position: relative;
    display: flex;
    padding: 20px;
    align-content: center;
    background-size: cover;
    background-position: center;
    overflow-x: hidden;
    cursor: grab;
    user-select: none;
    flex-wrap: wrap;
  }
  
  .timeline-container:active {
    cursor: grabbing;
  }
  
  .timeline {
    position: relative;
    display: flex;
    align-items: center;
    overflow-x: auto;
    padding-bottom: 200px;
  }
  
  .timeline::-webkit-scrollbar {
    display: none;
  }
  
  .chead {
    margin-bottom: 40px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    flex-direction: column;
  }
  
  .chead-left {
    display: flex;
    align-items: center;
  }
  
  .i18n {
    font-size: 32px;
  }
  
  .chead-desc {
    margin: 12px 0px;
    color: #727677;
    font-size: 16px;
    font-family: PingFangSC-Light, PingFang SC;
    font-weight: 300;
  }
  
  .about-time-entry {
    display: inline-block;
    margin: 0 40px;
    vertical-align: top;
    text-align: center;
    z-index: 999;
  }
  
  .about-time-title {
    margin: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-size: 20px;
    font-weight: 400;
    color: rgba(40, 40, 40, 1);
  }
  
  .about-time-content {
    margin: 12px;
    color: #727677;
    font-size: 16px;
    font-family: PingFangSC-Light, PingFang SC;
    font-weight: 300;
    width: 350px;
    word-wrap: break-word; /* 强制长单词换行 */
    word-break: break-all; /* 在必要时打断单词换行 */
    white-space: normal; /* 默认换行处理 */
}

  
  .about-time-info {
    display: flex; 
    align-items: center;
    transform: translateY(5px);
  }
  
  .icon-copy-bottom {
    max-width: 100%;
    height: auto;
    margin-right: 10px;
  }
  
  .about-time-time {
    font-size: 32px;
    font-family: Impact;
    color: #779649;
  }
  
  .about-dotted-line-left {
    height: 160px;
    border-left: #779649 solid 1px;
    margin-left: 8px;
    width: 296px;
    transform: translateY(16px);
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-content: flex-start;
  }
  
  .about-dotted-line-left.alternate-style {
    transform: translateY(193px);
    align-content: flex-end;
  }
  
  .about-dotted-line {
    height: 1px;
    border-top: 1px dashed #abafb1;
    position: absolute;
    transform: translateY(86px);
    z-index: 1;
  }
  
  @media (max-width: 768px) {
    .about-time-entry {
      margin: 0 20px;
    }
    
    .about-time-title, .about-time-content {
      font-size: 16px;
    }
  
    .about-time-time {
      font-size: 24px;
    }
  
    .about-dotted-line-left,
    .about-dotted-line-left.alternate-style {
      width: 200px;
    }
  }
  </style>
  