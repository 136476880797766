<template>
  <div>
    <div class="contact-us_content investment_flex" id="pc_address">
      <div class="investment_flex investment_flex_title">
        <div class="contact-us_content_title">
          <div class="address-info">
            <div class="address-item">
              <div class="address_box">
                <span class="address_head">地址</span>
              <img :src="subscript" class="address_sub">
              </div>
              <div class="text_addes">中国深圳</div>
              <div class="text_addes">中国香港</div>
            </div>
            <div class="address-item">
              <div class="address_box">
                <span class="address_head">电话</span>
              <img :src="subscript" class="address_sub">
              </div>
              <div class="text_addes">+86 13714913337</div>
            </div>
            <div class="address-item">
              <div class="address_box">
                <span class="address_head">邮箱</span>
                <img :src="subscript" class="address_sub">
              </div>
              <div class="text_addes">huanqiulvse@yeah.net</div>
            </div>
            <div class="address-item" v-show="false">
              <div class="address_box">
                <span class="address_head">邮编</span>
                <img :src="subscript" class="address_sub">
              </div>
              <div class="text_addes">200040</div>
            </div>
          </div>
          <img src="static/picture/us_shanghai.png" alt="" class="background-image">
        </div>
      </div>
      <img :src="addshUrl" alt="" class="map-image">
    </div>
  </div>
</template>

<script>
import subscript from "@/assets/subscript1.png";
import addshUrl from "@/assets/add_sh.png";

export default {
  name: 'ContactUsComponent',
  data() {
    return {
      subscript,
      addshUrl
    };
  }
};
</script>

<style scoped>
/* Reset and base styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html, body {
  height: 100%;
  font-family: Arial, sans-serif;
}

/* Contact us styles */
.contact-us_content {
  padding-left: 162px;
  width: 100%;
  margin-top: 110px;
}

.investment_flex {
  display: flex;
  justify-content: space-between;
}

.investment_flex_title {
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-between;
}

.contact-us_content_title {
  position: relative;
  width: 420px;
}

.address-info {
  position: relative;
  z-index: 55;
  margin-top: 30px;
}

.address-item {
  margin: 60px 0;
}

.address_head {
  font-size: 22px;
  display: block;
}
.address_box{
  width: 100%;
  display: flex;
}
.address_sub {
  position: relative;
  bottom: -20px;
  left: 4px;
  width: 8px;
  height: 8px;
}

.text_addes {
  margin-top: 10px;
}

.background-image {
  position: absolute;
  opacity: 0.68;
  bottom: 0;
  right: 23px;
}

.map-image {
  max-width: 58%;
  height: auto;
}

/* Responsive design */
@media (max-width: 48rem) {
  .contact-us_content {
    padding-left: 1rem;
  }

  .contact-us_content_title {
    width: 100%;
  }

  .address-item {
    margin: 30px 0;
  }

  .address_head {
    font-size: 18px;
  }

  .text_addes {
    font-size: 0.9rem;
  }

  .map-image {
    max-width: 50%;
    object-fit: cover;
  }
}
</style>
