<template>
    <div class="Profile_box">
      <div class="Profile_body">
        <h2>公司介绍</h2>
  
        <div class="body_select1">
          <div class="image_box">
            <el-image class="body_image" :src="url" fit="contain" />
          </div>
          <div class="body_text">
            <el-text class="mx-1" size="large">
              <span style="border-bottom: 1px solid black;">环球资本集团</span>作为<span>国内领先的“双碳”全方位服务商</span>，秉承“绿色低碳发展”的使命，
              <span>打造“智能+低碳”的碳管家云服务平台</span>。公司具有完善的组织机构和管理服务体系，专注于绿色低碳发展，践行绿色低碳理念，
              服务企业，促进节能减排节能降碳，保护环境，通过能源审计清洁，可持续发展，实现碳资产管理。<br>
              公司立足粤港澳大湾区，放眼全球。在欧盟和东盟设计分部市场，在国内，分设华东，华中，华南，华北，西南和西北介个分区的布局，在核心城市设立分公司。公司现有员工150余人、专业技术人员30人、碳核查员15人、碳管理师20人、检测监督员25人、检测内审员15人、注册化工工程师10人、计量检定员5人、特种作业员6人等。<br>
              公司专注于绿色低碳发展，高度重视科技研发工作，公司主要从事节能评审、节能验收、碳盘查、碳核查、碳资产开发、碳资产管理、绿色产品认证、绿色制造体系评价、工业固体废物资源综合利用评价、能源审计、清洁生产审核，具有行业丰富的专业知识和从业经验。
            </el-text>
          </div>
        </div>
        <div class="body_select2">
          <!-- <Carousel></Carousel> -->
          <mission v-if="!isPhone"></mission>
          <Appmission v-else></Appmission>
        </div>
        <h2>专家介绍</h2>

        <div class="body_select3">
          <structure v-if="!isPhone"></structure>
          <Appstructure v-else></Appstructure>
        </div>
        <div class="body_select4">
          <Advantage></Advantage>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { ref, onMounted, watch } from "vue";
  import url from "@/assets/f0f85b96713279876a82cc231a695bb6.webp";
  import Carousel from '@/components/Home/Carousel.vue'
  import structure from '@/components/Home/structure.vue'
  import mission from '@/components/Home/mission.vue'
  import Appstructure from '@/components/appHome/Appstructure.vue'
  import Appmission from '@/components/appHome/Appmission.vue'
  import Advantage from '@/components/Home/Advantage.vue'
  export default {
    components: {
      Carousel,structure,mission,Appstructure,Appmission,Advantage
    },
    setup() {
        const isPhone = ref(window.innerWidth <= 768);

        onMounted(() => {
            window.addEventListener('resize', () => {
                isPhone.value = window.innerWidth <= 768;
            });
        });
      return {
        url,isPhone
      }
    }
  }
  </script>
  
  <style lang="scss" scoped>
  
  .Profile_box {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
  }
  
  .Profile_body {
    width: 80%;
    margin-top: 60px;
    padding: 10px 30px 30px;
    border-radius: 30px;
    background: #ffffff;
  }
  
  h2 {
    font-size: 32px;
    font-family: monospace;
    font-weight: 600;
    text-align: center;
  }
  
  .body_select1 {
    margin: 20px;
    background-color: #f7f7f7;
    display: flex;
  }
  .mx-1{
    line-height: 25px;
  }
  .image_box {
    height: 380px;
    width: 60%;
    overflow: hidden;
  }
  
  .body_image {
    height: 100%;
    width: 100%;
    opacity: 1;
    transition: transform 0.3s ease, opacity 0.3s ease;
    user-select: none;
  }
  
  .body_image:hover {
    opacity: 1;
    transform: scale(1.2);
  }
  
  .body_text {
    padding: 20px;
    width: 40%;
  }
  
  .body_select2 {
    margin: 20px;
    background-color: #f7f7f7;
    // display: flex;
  }
  .body_select3{
    margin: 20px 20px 0px;
    background-color: #f7f7f7;
    // display: flex;
  }
  .body_select4{
    margin: 20px 20px 0px;
    background-color: #f7f7f7;
  }
  @media (max-width: 768px) {
    .Profile_body {
    width: 95%;
    margin-top: 20px;
    padding: 10px 15px 15px;
  }

  .body_select1 {
    flex-direction: column;
    align-items: center;
  }

  .image_box {
    width: 100%;
    height: auto;
  }

  .body_text {
    width: 100%;
    padding: 10px;
    line-height: 30px;
  }

  .body_image {
    width: 100%;
    height: auto;
  }

  .body_select2, .body_select3 {
    margin: 10px 0;
  }

  h2 {
    font-size: 24px;
  }
  }
  </style>
  