<template>
    <div class="index_main">
        <div class="section section1">
            <!-- <div v-if="!isPhone" class="index_banner" id="index_banner">
          <div class="inner">
            <div class="block_txt" data-anim="layui-anim-up">
              <h2 class="banner_title" :class="{ animate: titleAnimate }">{{ titles[currentIndex] }}</h2>
            </div>
          </div>
          <div v-for="(image, index) in images" :key="index" class="item" :class="{ active: index === currentIndex }"
            v-lazy:background-image="{ src: image }">
          </div>
        </div> -->
            <!-- <div v-else class="index_banner" id="index_banner">
          <div class="inner">
            <div class="block_txt" data-anim="layui-anim-up">
              <h2 class="banner_title" :class="{ animate: titleAnimate }">{{ titles[currentIndex] }}</h2>
            </div>
          </div>
          <div v-for="(image, index) in phoneBgs" :key="index" class="item" :class="{ active: index === currentIndex }"
            v-lazy:background-image="{ src: image }">
          </div>
        </div> -->
            <!-- <div class="footer_down">
          <button class="button" @click="scrollDown" aria-label="Scroll down">
            <svg class="svgIcon" viewBox="0 0 384 512">
              <path d="M214.6 41.4c-12.5-12.5-32.8-12.5-45.3 0l-160 160c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 141.2V448c0 17.7 14.3 32 32 32s32-14.3 32-32V141.2L329.4 246.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3l-160-160z"></path>
            </svg>
          </button>
        </div> -->
        </div>
        <div class="index_body">
            <CompanyProfile />
        </div>
        <div class="index_body">
            <partner v-if="!isPhone"></partner>
            <Apppartner v-else></Apppartner>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref, watch } from 'vue';
import image1 from '@/assets/image1.jpg';
import image2 from '@/assets/image2.jpg';
import image3 from '@/assets/image3.jpg';
import move_background1 from '@/assets/move_background1.jpg';
import move_background2 from '@/assets/move_background2.jpg';
import move_background3 from '@/assets/move_background3.jpg';
import CompanyProfile from '@/components/Home/CompanyProfile.vue';
import partner from '@/components/Home/partner.vue'
import Apppartner from '@/components/appHome/Apppartner.vue'

export default defineComponent({
    name: 'HomeView',
    components: {
        CompanyProfile, partner, Apppartner
    },
    setup() {
        const isPhone = ref(window.innerWidth <= 768);
        const images = ref([image1, image2, image3]);
        const phoneBgs = ref([move_background1, move_background2, move_background3]);
        const titles = ref([
            '发现未来，创造未来',
            '成就新一代企业家',
            '共创价值，与企业家同行',
        ]);
        const currentIndex = ref(0);
        const titleAnimate = ref(false);

        const preloadImages = (urls: string[]) => {
            urls.forEach((url: string) => {
                const img = new Image();
                img.src = url;
            });
        };


        onMounted(() => {
            preloadImages(images.value);
            preloadImages(phoneBgs.value);
            titleAnimate.value = true;
            startSlideshow();
            window.addEventListener('resize', () => {
                isPhone.value = window.innerWidth <= 768;
            });
        });

        const startSlideshow = () => {
            const totalItems = images.value.length;
            setInterval(() => {
                titleAnimate.value = false;
                setTimeout(() => {
                    currentIndex.value = (currentIndex.value + 1) % totalItems;
                    titleAnimate.value = true;
                }, 500);
            }, 6000);
        };

        const scrollDown = () => {
            window.scrollTo({
                top: window.innerHeight,
                behavior: 'smooth',
            });
        };

        watch(currentIndex, () => {
            titleAnimate.value = true;
        });

        return {
            images,
            titles,
            currentIndex,
            titleAnimate,
            scrollDown,
            phoneBgs,
            isPhone
        };
    },
});
</script>

<style scoped>
:root {
    --bg-color: #f7f7f7;
    --btn-bg-color: rgb(20, 20, 20);
    --btn-hover-bg-color: rgb(181, 160, 255);
    --btn-text-color: white;
}

.index_body {
    width: 100%;
    background: var(--bg-color);
}

.index_main {
    background: var(--bg-color);
}

.index_main .section1 {
    position: relative;
    margin-bottom: 100px; /* 默认是桌面端的样式 */
}

/* 移动端样式 */
@media (max-width: 768px) {
    .index_main .section1 {
        margin-bottom: 50px !important; /* 当屏幕宽度小于或等于 768px 时，应用这个样式 */
    }
}

.index_main .section1 .footer_down {
    position: absolute;
    left: 50%;
    bottom: 40px;
    transform: translateX(-50%);
    z-index: 999;
}

.index_main .section1 .footer_down .footer_down_txt {
    width: auto;
    height: 15px;
    font-size: 15px;
    font-weight: 400;
    color: #ffffff;
    line-height: 23px;
    text-align: center;
    margin-bottom: 14px;
}

.index_main .section1 .index_banner {
    position: relative;
    overflow: hidden;
    height: 100vh;
}

.index_main .section1 .index_banner .item {
    display: none;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    opacity: 0;
    transition: opacity 1s ease;
    will-change: opacity, transform;
    transform: translateZ(0);
}

.index_main .section1 .index_banner .item.active {
    display: block;
    opacity: 1;
    animation: scale-bg 6s linear forwards;
}

@keyframes scale-bg {
    from {
        transform: scale(1.2);
    }

    to {
        transform: scale(1);
    }
}

.banner_title {
    position: absolute;
    z-index: 1000;
    top: 50%;
    left: 50%;
    font-size: 43px;
    transform: translate(-50%, 100%);
    width: 100%;
    text-align: center;
    color: #ffffff;
    letter-spacing: 5px;
    font-family: PingFangSC-Light, PingFang SC;
    font-weight: 200;
    opacity: 0;
    transition: opacity 1.5s ease, transform 1.5s ease;
}

.banner_title.animate {
    opacity: 1;
    transform: translate(-50%, -50%);
}

.button {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: rgb(20, 20, 20);
    border: none;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 0px 0px 4px rgba(180, 160, 255, 0.253);
    cursor: pointer;
    transition-duration: 0.3s;
    overflow: hidden;
    position: relative;
    transform: rotate(180deg);
}

.svgIcon {
    width: 12px;
    transition-duration: 0.3s;
}

.svgIcon path {
    fill: white;
}

.button:hover {
    width: 140px;
    border-radius: 50px;
    transition-duration: 0.3s;
    background-color: rgb(181, 160, 255);
    align-items: center;
}

.button:hover .svgIcon {
    transition-duration: 0.3s;
    transform: translateY(-200%);
}

.button::before {
    position: absolute;
    bottom: -20px;
    content: "公 司 介 绍";
    color: white;
    transform: rotate(180deg);
    font-size: 0px;
}

.button:hover::before {
    font-size: 13px;
    opacity: 1;
    bottom: unset;
    transition-duration: 0.3s;
}


@media (max-width: 768px) {
    .inner {
        height: 100%;
        z-index: 1;
        position: relative;
    }
}
</style>