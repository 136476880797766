import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-15a08f8b"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "index_main" }
const _hoisted_2 = { class: "section section1" }
const _hoisted_3 = {
  key: 0,
  class: "index_banner",
  id: "index_banner"
}
const _hoisted_4 = { class: "inner" }
const _hoisted_5 = {
  class: "block_txt",
  "data-anim": "layui-anim-up"
}
const _hoisted_6 = {
  key: 1,
  class: "index_banner",
  id: "index_banner"
}
const _hoisted_7 = { class: "inner" }
const _hoisted_8 = {
  class: "block_txt",
  "data-anim": "layui-anim-up"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_lazy = _resolveDirective("lazy")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (!_ctx.isPhone)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("h2", {
                  class: _normalizeClass(["banner_title", { animate: _ctx.titleAnimate }])
                }, _toDisplayString(_ctx.titles[_ctx.currentIndex]), 3)
              ])
            ]),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.images, (image, index) => {
              return _withDirectives((_openBlock(), _createElementBlock("div", {
                key: index,
                class: _normalizeClass(["item", { active: index === _ctx.currentIndex }])
              }, null, 2)), [
                [_directive_lazy, { src: image }, "background-image"]
              ])
            }), 128))
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("div", _hoisted_8, [
                _createElementVNode("h2", {
                  class: _normalizeClass(["banner_title", { animate: _ctx.titleAnimate }])
                }, _toDisplayString(_ctx.titles[_ctx.currentIndex]), 3)
              ])
            ]),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.phoneBgs, (image, index) => {
              return _withDirectives((_openBlock(), _createElementBlock("div", {
                key: index,
                class: _normalizeClass(["item", { active: index === _ctx.currentIndex }])
              }, null, 2)), [
                [_directive_lazy, { src: image }, "background-image"]
              ])
            }), 128))
          ]))
    ])
  ]))
}