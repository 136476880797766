<template>
    <div>
      <div class="playground">
        <div class="animation-container">
          <div class="list">
            <h2 class="list-title" data-order="0">合作伙伴</h2>
            <div v-for="(item, index) in listItems" 
                 :key="index" 
                 :data-order="item.order" 
                 class="list-item"
                 :style="{ background: item.background}"
                 style="background-size: cover;"></div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import pg1 from '@/assets/pg1.jpg'
  import pg2 from '@/assets/pg2.jpg'
  import pg3 from '@/assets/pg3.jpg'
  import pg4 from '@/assets/pg4.jpg'
  import pg5 from '@/assets/pg5.jpg'
  import pg6 from '@/assets/pg6.jpg'
  import pg7 from '@/assets/pg7.jpg'
  import pg8 from '@/assets/pg8.jpg'
  import pg9 from '@/assets/pg9.jpg'
  import pg10 from '@/assets/pg10.jpg'
  import pg11 from '@/assets/pg11.jpg'
  import pg12 from '@/assets/pg12.jpg'
  import pg13 from '@/assets/pg13.jpg'
  import pg14 from '@/assets/pg14.jpg'
  import pg15 from '@/assets/pg15.jpg'
  import pg16 from '@/assets/pg16.jpg'
  import pg17 from '@/assets/pg17.jpg'
  import pg18 from '@/assets/pg18.jpg'
  import pg19 from '@/assets/pg19.jpg'
  import pg20 from '@/assets/pg20.jpg'
  export default {
    data() {
      return {
        listItems: [
          { order: 0, background: 'URL('+pg1+')' },
          { order: 1, background: 'URL('+pg2+')' },
          { order: 2, background: 'URL('+pg3+')' },
          { order: 3, background: 'URL('+pg4+')' },
          { order: 2, background: 'URL('+pg5+')' },
          { order: 1, background: 'URL('+pg6+')' },
          { order: 0, background: 'URL('+pg7+')' },
          { order: 0, background: 'URL('+pg8+')' },
          { order: 1, background: 'URL('+pg9+')' },
          { order: 2, background: 'URL('+pg10+')' },
          { order: 3, background: 'URL('+pg11+')' },
          { order: 2, background: 'URL('+pg12+')' },
          { order: 1, background: 'URL('+pg13+')' },
          { order: 0, background: 'URL('+pg14+')' },
          { order: 0, background: 'URL('+pg15+')' },
          { order: 1, background: 'URL('+pg16+')' },
          { order: 2, background: 'URL('+pg17+')' },
          { order: 3, background: 'URL('+pg18+')' },
          { order: 2, background: 'URL('+pg19+')' },
          { order: 1, background: 'URL('+pg20+')' },
        ],
        animationMap: new Map()
      };
    },
    methods: {
      createAnimation(xStart, xEnd, yStart, yEnd) {
        return function (x) {
          if (x <= xStart) {
            return yStart;
          }
          if (x >= xEnd) {
            return yEnd;
          }
          return yStart + ((x - xStart) / (xEnd - xStart)) * (yEnd - yStart);
        };
      },
      updateAnimationMap() {
        this.animationMap.clear();
        const items = this.$el.querySelectorAll('.list-item, .list-title');
        if (items.length === 0) {
          return;
        }
        const playGround = this.$el.querySelector('.playground');
        const playGroundRect = playGround.getBoundingClientRect();
        const scrollY = window.scrollY;
        const playGroundTop = playGroundRect.top + scrollY;
        const playGroundBottom = playGroundRect.bottom + scrollY - window.innerHeight;
  
        const list = this.$el.querySelector('.list');
        const listRect = list.getBoundingClientRect();
        for (let i = 0; i < items.length; i++) {
          const item = items[i];
          const scrollStart = playGroundTop + item.dataset.order * 600;
          const scrollEnd = playGroundBottom;
          const itemWidth = item.clientWidth;
          const itemHeight = item.clientHeight;
          const itemLeft = item.offsetLeft;
          const itemTop = item.offsetTop;
          const opacityAnimation = this.createAnimation(scrollStart, scrollEnd, 0, 1);
          const scaleAnimation = this.createAnimation(scrollStart, scrollEnd, 0.5, 1);
          const translateXAnimation = this.createAnimation(
            scrollStart,
            scrollEnd,
            listRect.width / 2 - itemLeft - itemWidth / 2,
            0
          );
          const translateYAnimation = this.createAnimation(
            scrollStart,
            scrollEnd,
            listRect.height / 2 - itemTop - itemHeight / 2,
            0
          );
          const animations = {
            opacity: (scrollY) => opacityAnimation(scrollY),
            transform: (scrollY) => {
              const scaled = scaleAnimation(scrollY);
              const x = translateXAnimation(scrollY);
              const y = translateYAnimation(scrollY);
              return `translate(${x}px, ${y}px) scale(${scaled})`;
            },
          };
          this.animationMap.set(item, animations);
        }
      },
      updateStyles() {
        const scrollY = window.scrollY;
        for (const [item, animations] of this.animationMap) {
          for (const prop in animations) {
            item.style[prop] = animations[prop](scrollY);
          }
        }
      }
    },
    mounted() {
      this.updateAnimationMap();
      this.updateStyles();
      window.addEventListener('scroll', this.updateStyles);
      window.addEventListener('resize', () => {
        this.updateAnimationMap();
        this.updateStyles();
      });
    },
    beforeDestroy() {
      window.removeEventListener('scroll', this.updateStyles);
      window.removeEventListener('resize', this.updateAnimationMap);
    }
  };
  </script>
  
  <style scoped>
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  html {
    overflow-x: hidden;
  }
  .header,
  .footer {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 4em;
  }
  .playground {
    height: 4000px;
    background: #a8bf8f;
  }
  
  .animation-container {
    position: sticky;
    height: 110vh;
    top: 0;
  }
  
  .list {
    position: absolute;
    left: 50%;
    transform: translate(-50%,-10%);
    width: 100%;
    aspect-ratio: 2/1;
    border-radius: 10px;
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    /* grid-template-rows: repeat(2, 1fr); */
    place-items: center;
  }
  
  .list-title {
    grid-column: span 7;
    text-align: center;
    font-size: 3.5em;
    color: #fff;
    /* margin-bottom: 20px; */
  }
  
  .list-item {
    width: 60%;
    aspect-ratio: 1/1;
    background: #fff;
    border-radius: 10px;
    background-size: cover;
  }
  
  .list-item:nth-child(3n + 1) {
    background: linear-gradient(#3e90f7, #246bf6);
  }
  .list-item:nth-child(3n + 2) {
    background: linear-gradient(#53b655, #469c50);
  }
  .list-item:nth-child(3n + 3) {
    background: linear-gradient(#f3a93c, #f4ad3d);
  }
  </style>
  